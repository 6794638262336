import accountService from "../../../services/accounts/account.service";
import { accountActionTypes } from "../../actionTypes/accounts/account.actionTypes";

export const login = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: accountActionTypes.START_LOGIN });

    const response = await accountService.login(credentials);
    const token = response.data.payload;
    dispatch({
      type: accountActionTypes.LOGIN_SUCCESS,
      data: token,
    });
    // Save the token to local storage
    localStorage.setItem("jwtToken", token);
  } catch (ex) {
    console.log("Login error: " + ex.message);
    dispatch({ type: accountActionTypes.LOGIN_FAILURE, error: ex.response?.data });
  }
};

export const register = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: accountActionTypes.START_REGISTER });

    const response = await accountService.register(userDetails);
    const token = response.data.payload;
    dispatch({
      type: accountActionTypes.REGISTER_SUCCESS,
      data: token,
    });
    // Save the token to local storage
    localStorage.setItem("jwtToken", token);
  } catch (ex) {
    console.log("Register error: " + ex.message);
    dispatch({
      type: accountActionTypes.REGISTER_FAILURE,
      error: ex.response?.data,
    });
  }
};

export const resetUserPassword = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: accountActionTypes.START_RESET_USER_PASSWORD });

    const response = await accountService.resetUserPassword(credentials);
    dispatch({ type: accountActionTypes.RESET_USER_PASSWORD_SUCCESS });

  } catch (ex) {
    console.log("reset user password error: " + ex.message);
    dispatch({ type: accountActionTypes.RESET_USER_PASSWORD_FAILURE, error: ex.response?.data });
  }
};

export const changeUserPassword = (data) => async (dispatch) => {
  try {
    dispatch({ type: accountActionTypes.START_CHANGE_USER_PASSWORD });

    const response = await accountService.changeUserPassword(data);
    dispatch({ type: accountActionTypes.CHANGE_USER_PASSWORD_SUCCESS });

  } catch (ex) {
    console.log("change user password error: " + ex.message);
    dispatch({ type: accountActionTypes.CHANGE_USER_PASSWORD_FAILURE, error: ex.response?.data });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  dispatch({ type: accountActionTypes.LOGOUT });
  window.location.reload(); // Refresh page to apply changes
};

export const getUserInfo = (data) => async (dispatch) => {
  try {
    dispatch({ type: accountActionTypes.START_GET_USER_INFO });

    const response = await accountService.getUserInfo(data);
    dispatch({
      type: accountActionTypes.GET_USER_INFO_SUCCESS,
      data: response.data.payload,
    });
  } catch (ex) {
    console.log("Get user info error: " + ex.message);
    dispatch({
      type: accountActionTypes.GET_USER_INFO_FAILURE,
      error: ex.response?.data,
    });
  }
};

const accountActions = {
  login,
  register,
  resetUserPassword,
  changeUserPassword,
  logout,
  getUserInfo,
};

export default accountActions;
