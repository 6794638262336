import { useState } from "react";
import "../../../styles/components/elements/inputElements/textInputElementComponent.scss";

export const TextAreaInputElementComponent = ({ props }) => {
  const { name, label, value, onChange, required, clearBaseDesign, customStyleClass, error } = props;
  return (
    <div className={`${clearBaseDesign ? "" : "textElementContainer"} ${customStyleClass ? customStyleClass : ""}`}>
      <label>{label}</label>
      <textarea name={name} value={value} onChange={onChange} required={required} />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default TextAreaInputElementComponent;
