import { BrowserRouter as Router } from "react-router-dom";

import AppRoutes from "./installations/routes/AppRoutingProvider";
import HeaderComponent from "./components/layouts/header/HeaderComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DialogManagerComponent from "./components/dialogs/DialogManagerComponent";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import categoryActions from "./store/actions/products/categories/category.actions";

// Create a Query Client instance
const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = { getAll: true };
    dispatch(categoryActions.getCategories(params));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <HeaderComponent />
          <div className="container">
            <AppRoutes />
          </div>
        </div>
        <DialogManagerComponent />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
