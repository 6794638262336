import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPageComponent from "../../pages/accounts/LoginPageComponent";
import RegisterPageComponent from "../../pages/accounts/RegisterPageComponent";
import ResetUserPasswordPageComponent from "../../pages/accounts/ResetUserPasswordPageComponent";
import ChangeUserPasswordPageComponent from "../../pages/accounts/ChangeUserPasswordPageComponent";
import GetUserInfoPageComponent from "../../pages/accounts/GetUserInfoPageComponent";

// Administrator dashboard routes
import AdminRoute from "../../middlewares/routing/AdminRoute.middleware";
import AdminDashboardPageComponent from "../../pages/dashboard/admin/AdminDashboardPageComponent";
import AdminCategoriesListPageComponent from "../../pages/dashboard/admin/categories/AdminCategoriesListPageComponent";
import AdminProductsListPageComponent from "../../pages/dashboard/admin/products/AdminProductsListPageComponent";
import AdminProductsAttributesListPageComponent from "../../pages/dashboard/admin/products/attributes/AdminProductsAttributesListPageComponent";
import ProductGalleryPageComponent from "../../pages/products/ProductGalleryPageComponent";
import ProductDetailsPageComponent from "../../pages/products/ProductDetailsPageComponent";
import ShoppingCartPageComponent from "../../pages/shoppingCart/ShoppingCartPageComponent";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/admin/login" element={<LoginPageComponent />} />

      <Route path="/register" element={<RegisterPageComponent />} />
      <Route path="/reset-password" element={<ResetUserPasswordPageComponent />} />
      <Route path="/change-password" element={<ChangeUserPasswordPageComponent />} />
      <Route path="/user-info" element={<GetUserInfoPageComponent />} />

      <Route path="/categories/:categoryId" element={<ProductGalleryPageComponent />} />
      <Route path="product/:productId" element={<ProductDetailsPageComponent />} />

      <Route path="shoppingCart" element={<ShoppingCartPageComponent />} />

      <Route path="admin/dashboard/:userId" element={<AdminRoute />}>
        <Route index element={<AdminDashboardPageComponent />} />
        <Route path="categories" element={<AdminCategoriesListPageComponent />} />
        <Route path="products/attributes" element={<AdminProductsAttributesListPageComponent />} />
        <Route path="products" element={<AdminProductsListPageComponent />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
