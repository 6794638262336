import { Link } from "react-router-dom";

export const AdminDashboardPageComponent = () => {
  return (
    <div>
      <h1>איזור ניהול</h1>

      <div>
        <h5>קטגוריות</h5>
        <Link to="categories">ניהול קטגוריות</Link>
      </div>
      <div>
        <h5>משתמשים</h5>
        <Link to="?d=account&action=manage">ניהול משתמשים</Link>
      </div>
      <div>
        <h5>הגדרות אתר</h5>
        <Link to="?d=websiteComfiguration&action=manage">ניהול הגדרות אתר</Link>
      </div>
      <div>
        <h5>פריטים</h5>
        <Link to="products/attributes">ניהול מאפייני פריט</Link>
        <Link to="products">ניהול פריטים</Link>
      </div>
      <div>
        <h5>הזמנות</h5>
        <Link to="orders">רשימת הזמנות</Link>
      </div>

      {/* <BusinessOrdersTableComponent /> */}
    </div>
  );
};

export default AdminDashboardPageComponent;
