import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { productModel } from "../../../models/products/product.model";
import productActions from "../../../store/actions/products/product.actions";
import validationHelper from "../../../helpers/validation.helper";
import AlertService from "../../../services/alerts/alert.service";
import SelectInputElementComponent from "../../elements/inputElements/SelectInputElementComponent";
import TextAreaInputElementComponent from "../../elements/inputElements/TextAreaInputElementComponent";
import TextInputElementComponent from "../../elements/inputElements/TextInputElementComponent";
import textInputModel from "../../../models/elements/inputElements/textInput.model";
import SwitchElementComponent from "../../elements/inputElements/SwitchElementComponent";

const AddOrUpdateProductDialogComponent = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const categories = useSelector((state) => state.categoryReducer.categories.items);

  const [productDetails, setProductDetails] = useState(productModel);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});

  const actionType = searchParams.get("action");
  const productId = searchParams.get("id");

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (actionType === "update" && productId) {
      fetchProductDetails();
    }
  }, [actionType, productId]);

  const fetchProductDetails = async () => {
    try {
      const response = await dispatch(productActions.getProductById(productId));

      if (!validationHelper.validateHttpResponse(response, true)) {
        AlertService.error("שגיאה בטעינת פרטי המוצר", "אנא נסה שוב מאוחר יותר");
        return;
      }

      setProductDetails(response.payload);
    } catch (error) {
      AlertService.error("שגיאה בטעינת פרטי המוצר", "אנא נסה שוב מאוחר יותר");
      console.error("Error fetching product details:", error);
    }
  };

  const fetchCategories = async () => {
    const queryParams = { getAll: true };
    try {
      await dispatch(productActions.getProducts(queryParams));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // ********** Input Change Events ********
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setProductDetails({ ...productDetails, [name]: checked });
  };

  // ********** Submit Events ********
  const validateForm = () => {
    const newErrors = {};

    if (!productDetails.productName || productDetails.productName.trim() === "") {
      newErrors.productName = "שם המוצר הוא שדה חובה";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    try {
      if (actionType === "update") await handleUpdateProduct(productDetails);
      else await handleAddProduct(productDetails);
    } catch (error) {
      setErrors({ general: "שגיאה בעת שמירת הנתונים. נסה שוב מאוחר יותר." });
    }
  };

  const handleClearSearchParams = () => {
    setSearchParams(new URLSearchParams()); // Clears all search parameters
  };

  const sanitizeProductData = (data) => {
    return {
        ...data,
        salePrice: data.salePrice === "" ? null : data.salePrice,
        bulkDiscount: data.bulkDiscount === "" ? null : data.bulkDiscount,
        bulkDiscountThreshold: data.bulkDiscountThreshold === "" ? null : data.bulkDiscountThreshold
    };
};

  const handleAddProduct = async (request) => {
    const sanitizeProduct = sanitizeProductData(request);
    const response = await dispatch(productActions.addProduct(sanitizeProduct));
    if (!validationHelper.validateHttpResponse(response, false)) {
      setErrors({ general: "הוספת מוצר נכשלה. נסה שוב." });
      return;
    }
    AlertService.success("מוצר נוסף בהצלחה");
    handleClearSearchParams();
  };

  const handleUpdateProduct = async (request) => {
    const sanitizeProduct = sanitizeProductData(request);
    const response = await dispatch(productActions.updateProduct(sanitizeProduct));
    if (!validationHelper.validateHttpResponse(response, false)) {
      setErrors({ general: "עדכון המוצר נכשל" });
      return;
    }
    AlertService.success("המוצר עודכן בהצלחה");
    handleClearSearchParams();
  };

  const handleUpdateImage = async () => {
    if (!imageFile || !productId) {
      AlertService.error("אנא בחר תמונה ולאחר מכן נסה שוב.");
      return;
    }

    const formData = new FormData();
    formData.append("file", imageFile);

    try {
      const response = await dispatch(productActions.addProductImage(productId, formData, true));

      if (!validationHelper.validateHttpResponse(response)) {
        AlertService.error("שגיאה בעדכון התמונה", "אנא נסה שוב מאוחר יותר");
        return;
      }

      AlertService.success("התמונה עודכנה בהצלחה");
      fetchProductDetails();
    } catch (error) {
      AlertService.error("שגיאה בעדכון התמונה", "אנא נסה שוב מאוחר יותר");
      console.error("Update product image error:", error);
    }
  };

  // ********** Get Elements ********
  const getInputField = (name, label, type = "text") => {
    const inputProps = {
      ...textInputModel,
      name,
      value: productDetails[name],
      error: errors[name],
      onChange: handleInputChange,
      label,
      type,
    };
    return <TextInputElementComponent props={inputProps} />;
  };

  const getStatusSelectElement = () => {
    const statusOptions = [
      { value: "Draft", label: "טיוטה" },
      { value: "Active", label: "פעיל" },
      { value: "OutOfStock", label: "אזל מהמלאי" },
      { value: "Discontinued", label: "הופסק" },
      { value: "ComingSoon", label: "בקרוב" },
    ];

    const inputProps = {
      name: "status",
      value: productDetails.status,
      options: statusOptions,
      onChange: handleInputChange,
      searchable: false,
      label: "סטטוס מוצר",
    };

    return <SelectInputElementComponent props={inputProps} />;
  };

  const getCategoriesSelectElement = () => {
    return (
      <SelectInputElementComponent
        props={{
          name: "categoryID",
          value: productDetails.categoryID,
          options: categories?.map((cat) => ({ value: cat.id, label: cat.categoryName })),
          onChange: handleInputChange,
          searchable: true,
          label: "קטגוריה",
        }}
      />
    );
  };

  const getIsAvailableSwitchElement = () => {
    const switchProps = {
      name: "isAvailable",
      label: "זמינות המוצר באתר",
      checked: productDetails?.isAvailable || false,
      onChange: handleSwitchChange,
      customDesignClass: "customSwitchClass",
    };
    return <SwitchElementComponent props={switchProps} />;
  };

  const getProductImageInputElement = () => {
    if (actionType !== "update") return null;
    return (
      <div className="dialogContainer imageUploadContainer">
        <label>עדכן תמונה:</label>
        <input type="file" accept="image/*" onChange={handleImageChange} />
        <button type="button" className="secondaryButton" onClick={handleUpdateImage}>
          עדכון תמונה
        </button>
      </div>
    );
  };

  return (
    <div className="addProductDialogContainer">
      <h1>{actionType === "update" ? "עדכון מוצר" : "הוסף מוצר"}</h1>
      <form onSubmit={handleSubmit}>
        <span>פרטים כלליים</span>
        {getInputField("productName", "שם מוצר")}
        {getCategoriesSelectElement()}
        {getInputField("displayOrder", "סדר תצוגה")}
        <TextAreaInputElementComponent props={{ name: "description", value: productDetails.description, onChange: handleInputChange, label: "תיאור מוצר" }} />

        <span>זמינות ומלאי</span>
        {getIsAvailableSwitchElement()}
        {getStatusSelectElement()}
        {getInputField("stockQuantity", "כמות במלאי")}
        {getInputField("reservedQuantity", "כמות מוזמנת")}
        {getInputField("sKU", "SKU")}
        {getInputField("barcode", "ברקוד")}

        <span>מחירים והנחות</span>
        {getInputField("price", "מחיר", "number")}
        {/* {getInputField("discountedPrice", "מחיר מבצע", "number")} */}
        {getInputField("salePrice", "מחיר במבצע", "number")}
        {getInputField("bulkDiscount", "הנחה לרכישה בכמות", "number")}
        {getInputField("bulkDiscountThreshold", "כמות מינימלית להנחה", "number")}

        <span>עלויות ורווח</span>
        {getInputField("baseCost", "עלות בסיסית", "number")}
        {getInputField("packagingCost", "עלות אריזה", "number")}
        {getInputField("shippingCost", "עלות משלוח", "number")}
        {getInputField("handlingCost", "עלות טיפול", "number")}
        {getInputField("importCost", "עלות יבוא", "number")}

        <span>משקל ומידות</span>
        {getInputField("weight", "משקל (גרם)", "number")}
        {getInputField("width", 'רוחב (ס"מ)', "number")}
        {getInputField("height", 'גובה (ס"מ)', "number")}
        {getInputField("depth", 'עומק (ס"מ)', "number")}

        {/* {getProductImageInputElement()} */}

        <button type="submit" className="primaryButton">
          {actionType === "update" ? "עדכון" : "הוסף"}
        </button>
        {errors.general && <span className="error">{errors.general}</span>}
      </form>
    </div>
  );
};

export default AddOrUpdateProductDialogComponent;
