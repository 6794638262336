import { appHttpClient } from "../../../installations/http/axiosInterfaces";

export const addAttribute = (data) => {
  return appHttpClient.post(`/Attribute/Add`, data);
};

export const updateAttribute = (data) => {
  return appHttpClient.patch(`/Attribute/Update`, data);
};

export const deleteAttribute = (attributeId) => {
  return appHttpClient.delete(`/Attribute/Delete/${attributeId}`);
};

export const getAttributeById = (attributeId) => {
  return appHttpClient.get(`/Attribute/${attributeId}`);
};

export const getAttributes = (queryParams) => {
  return appHttpClient.get(`/Attribute/GetAttributes`, { params: queryParams });
};

export const addAttributeValue = (data) => {
  return appHttpClient.post(`/Attribute/AddValue`, data);
};

export const updateAttributeValue = (data) => {
  return appHttpClient.patch(`/Attribute/UpdateValue`, data);
};

export const deleteAttributeValue = (valueId) => {
  return appHttpClient.delete(`/Attribute/DeleteValue/${valueId}`);
};

export const getAttributeValues = (attributeId) => {
  return appHttpClient.get(`/Attribute/Values/${attributeId}`);
};

export const connectAttributeWithProduct = (productId, attributeId, attributeValueId) => {
  return appHttpClient.post(`/Attribute/ConnectProduct/${productId}/${attributeId}/${attributeValueId}`);
};

export const disconnectAttributeWithProduct = (productId, attributeId, attributeValueId) => {
  return appHttpClient.post(`/Attribute/DisconnectProduct/${productId}/${attributeId}/${attributeValueId}`);
};

const attributeService = {
  addAttribute,
  updateAttribute,
  deleteAttribute,
  getAttributeById,
  getAttributes,
  addAttributeValue,
  updateAttributeValue,
  deleteAttributeValue,
  getAttributeValues,
  connectAttributeWithProduct,
  disconnectAttributeWithProduct,
};

export default attributeService;
