import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import accountActions from "../../store/actions/accounts/account.actions";

export const LoginPageComponent = ({accountActions}) => {
  const defaultCredentials = { email: "", password: "" };
  const [credentails, setCredentials] = useState(defaultCredentials);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentails, [name]: value });
  };

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      const response = await accountActions.login(credentails);
    } catch (error) {}
  };

  return (
    <div>
      <form onSubmit={handleLogin}>
        <input type="text" placeholder="enter email" name="email" value={credentails.email} onChange={handleInputChange} />
        <input type="password" placeholder="enter password" name="password" value={credentails.password} onChange={handleInputChange} />
        <button onClick={handleLogin}>login</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
