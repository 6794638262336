import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import usePagination from "../../../../../hooks/usePagination";
import attributeActions from "../../../../../store/actions/products/attributes/attribute.actions";
import AlertService from "../../../../../services/alerts/alert.service";
import TableElementComponent from "../../../../../components/elements/TableElementComponent";
import textInputModel from "../../../../../models/elements/inputElements/textInput.model";
import TextInputElementComponent from "../../../../../components/elements/inputElements/TextInputElementComponent";
import LoaderComponent from "../../../../../components/elements/LoaderComponent";

const buttonsActions = { delete: "delete", edit: "edit" };

const AdminProductsAttributesListPageComponent = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  // 🔹 Fetch attributes from Redux Store
  const attributes = useSelector((state) => state.attributeReducer.attributes.items);

  const { fetchNextPage, hasNextPage, refetch } = usePagination({
    queryKey: ["admin-attributes", searchQuery],
    fetchFunction: async (pageParam) => {
      const params = {
        pageNumber: pageParam,
        pageSize: 10,
        searchTerm: searchQuery,
      };
      return await dispatch(attributeActions.getAttributes(params));
    },
  });

  useEffect(() => {
    if (!searchParams.get("d")) {
      refetch();
    }
  }, [searchParams]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleActionClick = (action, attributeId) => {
    switch (action) {
      case buttonsActions.delete:
        handleDeleteAttribute(attributeId);
        break;
      case buttonsActions.edit:
        handleEditAttribute(attributeId);
        break;
      default:
        break;
    }
  };

  const handleDeleteAttribute = async (attributeId) => {
    const attribute = attributes.find((a) => a.id === attributeId);
    const { id, name, attributeValues } = attribute;

    if (attributeValues && attributeValues.length > 0) {
      AlertService.warning("לא ניתן למחוק מאפיין", `למאפיין "${name}" יש ערכים משויכים. יש למחוק תחילה את כל הערכים.`);
      return;
    }

    const isConfirmed = await AlertService.confirm("מחיקת מאפיין", `האם אתה בטוח שברצונך למחוק את המאפיין "${name}"?`, "כן, מחק", "ביטול");

    if (isConfirmed) {
      try {
        await dispatch(attributeActions.deleteAttribute(id));
        AlertService.success("המאפיין נמחק", `המאפיין "${name}" נמחק בהצלחה.`);
        refetch();
      } catch (error) {
        AlertService.error("שגיאה במחיקה", "אירעה שגיאה בעת מחיקת המאפיין. נסה שוב מאוחר יותר.");
      }
    }
  };

  const handleEditAttribute = (attributeId) => {
    const queryParams = new URLSearchParams();
    queryParams.set("d", "attribute");
    queryParams.set("action", "update");
    queryParams.set("id", attributeId);

    setSearchParams(queryParams);
  };

  const getAttributesTable = () => {
    const columns = [
      { label: "שם מאפיין", field: "name" },
      { label: "תיאור", field: "description" },
      {
        label: "מספר ערכים",
        field: "attributeValues",
        render: (value) => <span>{value?.length || 0}</span>,
      },
    ];

    const actions = [
      {
        label: "עריכה",
        icon: "fa-solid fa-edit",
        className: "greenButton",
        onClick: (attribute) => handleActionClick(buttonsActions.edit, attribute.id),
      },
      {
        label: "מחיקת מאפיין",
        icon: "fa-solid fa-trash",
        className: "redButton",
        onClick: (attribute) => handleActionClick(buttonsActions.delete, attribute.id),
      },
    ];

    return <TableElementComponent columns={columns} data={attributes} actions={actions} />;
  };

  const getNoAttributesElement = () => (
    <div className="noItemsContainer">
      <h2>לא נמצאו מאפיינים</h2>
      <p>נסה לשנות את הקריטריונים לחיפוש או לבדוק מאפיינים קיימים.</p>
    </div>
  );

  const getSearchInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "searchInput",
      value: searchQuery,
      placeholder: "חפש מאפיין",
      onChange: handleSearch,
      icon: <i className="fa-solid fa-magnifying-glass"></i>,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  const getNewAttributeContainerElement = () => {
    return (
      <div className="newAttributeContainer">
        <Link to="?d=attribute&action=add" className="addAttributeButton primaryButton">
          הוסף מאפיין
        </Link>
      </div>
    );
  };

  return (
    <div className="adminAttributeListContainer">
      <h1>ניהול מאפיינים</h1>
      <div className="searchContainer">
        {getSearchInputElement()} {getNewAttributeContainerElement()}
      </div>

      {attributes?.length > 0 ? (
        <InfiniteScroll dataLength={attributes.length} next={fetchNextPage} hasMore={hasNextPage} loader={<LoaderComponent />}>
          <div className="attributeListContainer">{getAttributesTable()}</div>
        </InfiniteScroll>
      ) : (
        getNoAttributesElement()
      )}
    </div>
  );
};

export default AdminProductsAttributesListPageComponent;
