import { useState, useLayoutEffect, useEffect } from "react";

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const useMobileScreen = (mobileMaxWidth = breakpoints.sm) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("xs");

  const [windowSize, setWindowSize] = useState({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  });

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setWindowSize({ innerWidth: width, innerHeight: window.innerHeight });

      // Determine the current breakpoint
      let breakpoint = "xs"; // Default to smallest
      for (const key in breakpoints) {
        if (width >= breakpoints[key]) {
          breakpoint = key;
        }
      }
      setCurrentBreakpoint(breakpoint);

      // Check if screen is mobile
      setIsMobileScreen(width <= mobileMaxWidth);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return { isMobileScreen, currentBreakpoint };
};

export default useMobileScreen;
