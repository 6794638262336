import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import usePagination from "../../../../hooks/usePagination";
import categoryActions from "../../../../store/actions/products/categories/category.actions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import TableElementComponent from "../../../../components/elements/TableElementComponent";
import textInputModel from "../../../../models/elements/inputElements/textInput.model";
import LoaderComponent from "../../../../components/elements/LoaderComponent";
import TextInputElementComponent from "../../../../components/elements/inputElements/TextInputElementComponent";
import AlertService from "../../../../services/alerts/alert.service";

// import "../../../styles/pages/dashboard/adminManageItemPageComponent.scss";
// import "../../../styles/components/general/elements/table/customTableDesign.scss";

const buttonsActions = { delete: "delete", edit: "edit" };

export const AdminCategoriesListPageComponent = ({ categoryActions }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(""); // User's input value
  const [searchQuery, setSearchQuery] = useState(""); // Actual query sent to API

  const {
    items: categories,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = usePagination({
    queryKey: ["admin-categories", searchQuery], // Only updates when searchQuery changes
    fetchFunction: async (pageParam) => {
      const params = {
        pageNumber: pageParam,
        pageSize: 10,
        searchTerm: searchQuery, // Use searchQuery instead of searchTerm
      };
      const response = await categoryActions.getCategories(params);
      return response;
    },
  });

  useEffect(() => {
    if (!searchParams.get("d")) {
      refetch(); // ✅ Refetch data when the dialog is closed
    }
  }, [searchParams]);

  // 🔍 Handle search when the button is clicked
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value); // Update the query state → triggers fetch
  };

  const handleActionClick = (action, categoryId) => {
    switch (action) {
      case buttonsActions.delete:
        handleDeleteCategory(categoryId);
        break;
      case buttonsActions.edit:
        handleEditCategory(categoryId);
        break;
      default:
        break;
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    const { id, categoryName, subCategories } = category;
    // Check if category has child categories
    if (subCategories && subCategories.length > 0) {
      AlertService.warning("לא ניתן למחוק קטגוריה", `הקטגוריה "${categoryName}" מכילה קטגוריות משנה. יש למחוק תחילה את כל קטגוריות המשנה שלה.`);
      return;
    }
    // Ask for confirmation before deletion
    const isConfirmed = await AlertService.confirm("מחיקת קטגוריה", `האם אתה בטוח שברצונך למחוק את הקטגוריה "${categoryName}"?`, "כן, מחק", "ביטול");
    if (isConfirmed) {
      try {
        await categoryActions.deleteCategory(id); // Delete the category from the API
        AlertService.success("הקטגוריה נמחקה", `הקטגוריה "${categoryName}" נמחקה בהצלחה.`);
        refetch(); // Refresh the data to reflect the deletion
      } catch (error) {
        AlertService.error("שגיאה במחיקה", "אירעה שגיאה בעת מחיקת הקטגוריה. נסה שוב מאוחר יותר.");
      }
    }
  };

  const handleEditCategory = (categoryId) => {
    const queryParams = new URLSearchParams();
    queryParams.set("d", "category");
    queryParams.set("action", "update");
    queryParams.set("id", categoryId);

    setSearchParams(queryParams);
  };

  const getCategoriesTable = () => {
    const columns = [
      {
        label: "תמונה",
        field: "imageURL",
        render: (value, row) => (
          <div className="imageColumnContainer">
            <img src={value || "https://via.placeholder.com/50"} alt={row.categoryName} />
          </div>
        ),
      },
      { label: "שם קטגוריה", field: "categoryName" },
      { label: "תיאור", field: "description" },
      {
        label: "קטגוריה ראשית",
        field: "parentCategoryID",
        render: (value, row) => <span>{row.parentCategoryID ? "לא" : "כן"}</span>,
      },
      { label: "סדר תצוגה", field: "displayOrder" },
    ];

    const actions = [
      {
        label: "עריכה",
        icon: "fa-solid fa-edit",
        className: "greenButton",
        onClick: (category) => handleActionClick(buttonsActions.edit, category.id),
      },
      {
        label: "מחיקת קטגוריה",
        icon: "fa-solid fa-trash",
        className: "redButton",
        onClick: (category) => handleActionClick(buttonsActions.delete, category.id),
      },
    ];

    return <TableElementComponent columns={columns} data={categories} actions={actions} />;
  };

  const getNoCategoriesElement = () => (
    <div className="noItemsContainer">
      <h2>לא נמצאו קטגוריות</h2>
      <p>נסה לשנות את הקריטריונים לחיפוש או לבדוק קטגוריות קיימות.</p>
    </div>
  );

  const getSearchInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "searchInput",
      value: searchQuery,
      placeholder: "חפש קטגוריה",
      onChange: handleSearch,
      icon: <i className="fa-solid fa-magnifying-glass"></i>,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  const getNewCategoryContainerElement = () => {
    return (
      <div className="newCategoryContainer">
        <Link to="?d=category&action=add" className="addCategoryButton primaryButton">
          הוסף קטגוריה
        </Link>
      </div>
    );
  };

  return (
    <div className="adminCategoryListContainer">
      <h1>ניהול קטגוריות</h1>
      <div className="searchContainer">
        {getSearchInputElement()} {getNewCategoryContainerElement()}
      </div>

      {categories?.length > 0 ? (
        <InfiniteScroll dataLength={categories.length} next={fetchNextPage} hasMore={hasNextPage} loader={<LoaderComponent />}>
          <div className="categoryListContainer">{getCategoriesTable()}</div>
        </InfiniteScroll>
      ) : (
        getNoCategoriesElement()
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  categoryActions: bindActionCreators(categoryActions, dispatch),
});

export default connect(null, mapDispatchToProps)(AdminCategoriesListPageComponent);
