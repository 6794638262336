import { categoryActionTypes } from "../../../actionTypes/products/categories/category.actionTypes";

const initialState = {
    stateInfo: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: null,
    },
    categories: [],
    selectedCategory: null,
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case categoryActionTypes.START_ADD_CATEGORY:
        case categoryActionTypes.START_UPDATE_CATEGORY:
        case categoryActionTypes.START_DELETE_CATEGORY:
        case categoryActionTypes.START_GET_CATEGORY_BY_ID:
        case categoryActionTypes.START_GET_CATEGORIES:
            return setStateLoading(state);

        case categoryActionTypes.ADD_CATEGORY_SUCCESS:
        case categoryActionTypes.UPDATE_CATEGORY_SUCCESS:
            return setStateSuccess(state);

        case categoryActionTypes.DELETE_CATEGORY_SUCCESS:
            return setStateSuccess(state);

        case categoryActionTypes.GET_CATEGORY_BY_ID_SUCCESS:
            return setSelectedCategory(state, action);

        case categoryActionTypes.GET_CATEGORIES_SUCCESS:
            return setCategories(state, action);

        case categoryActionTypes.ADD_CATEGORY_FAILURE:
        case categoryActionTypes.UPDATE_CATEGORY_FAILURE:
        case categoryActionTypes.DELETE_CATEGORY_FAILURE:
        case categoryActionTypes.GET_CATEGORY_BY_ID_FAILURE:
        case categoryActionTypes.GET_CATEGORIES_FAILURE:
            return setStateFailure(state, action);

        default:
            return state;
    }
};

const setStateLoading = (state) => ({
    ...state,
    stateInfo: {
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: null,
    },
});

const setStateSuccess = (state) => ({
    ...state,
    stateInfo: {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: null,
    },
});

const setStateFailure = (state, action) => ({
    ...state,
    stateInfo: {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: action.error,
    },
});

const setCategories = (state, action) => ({
    ...state,
    categories: action.data,
    stateInfo: {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: null,
    },
});

const setSelectedCategory = (state, action) => ({
    ...state,
    selectedCategory: action.data,
    stateInfo: {
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: null,
    },
});

export default categoryReducer;
