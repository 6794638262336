import axios from "axios";

const appHttpClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// ✅ Add an Axios interceptor to attach token if available
appHttpClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken"); // Get token from localStorage

    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add token to headers
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { appHttpClient };
