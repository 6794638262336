import React from "react";
import { fileTypesEnum } from "../../../enums/fileTypes.enum";

import "../../../styles/components/elements/inputElements/fileInputElementComponent.scss";

export const FileInputElementComponent = ({ props }) => {
  const { name, label, value, required, onChange, fileType } = props;

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileDetails = await getFileDetails(file);
    onChange(file, fileDetails);
  };

  const getFileDetails = (file) => {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          resolve({ file, url: imageUrl, type: file.type });
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      } else {
        reject("No file provided");
      }
    });
  };

  return (
    <div className="file-element-container">
      {fileType === fileTypesEnum.image && value && (
        <div className="file-viewer-container">
          <img src={value} alt="Selected file" />
        </div>
      )}
      <div className="file-input-container">
        <label htmlFor={name}>{label}</label>
        <input type="file" id={name} name={name} onChange={handleFileChange} required={required} />
      </div>
    </div>
  );
};

export default FileInputElementComponent;
