import attributeService from "../../../../services/products/attributes/attribute.service";
import { attributeActionTypes } from "../../../actionTypes/products/attributes/attribute.actionTypes";

// Add Attribute
export const addAttribute = (attributeData) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_ADD_ATTRIBUTE });
    const response = await attributeService.addAttribute(attributeData);
    dispatch({
      type: attributeActionTypes.ADD_ATTRIBUTE_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Add attribute error:", ex.message);
    dispatch({ type: attributeActionTypes.ADD_ATTRIBUTE_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Update Attribute
export const updateAttribute = (attributeData) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_UPDATE_ATTRIBUTE });
    const response = await attributeService.updateAttribute(attributeData);
    dispatch({
      type: attributeActionTypes.UPDATE_ATTRIBUTE_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Update attribute error:", ex.message);
    dispatch({ type: attributeActionTypes.UPDATE_ATTRIBUTE_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Delete Attribute
export const deleteAttribute = (attributeId) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_DELETE_ATTRIBUTE });
    const response = await attributeService.deleteAttribute(attributeId);
    dispatch({ type: attributeActionTypes.DELETE_ATTRIBUTE_SUCCESS });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Delete attribute error:", ex.message);
    dispatch({ type: attributeActionTypes.DELETE_ATTRIBUTE_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Get Attribute by ID
export const getAttributeById = (attributeId) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_GET_ATTRIBUTE_BY_ID });
    const response = await attributeService.getAttributeById(attributeId);
    dispatch({
      type: attributeActionTypes.GET_ATTRIBUTE_BY_ID_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Get attribute by ID error:", ex.message);
    dispatch({ type: attributeActionTypes.GET_ATTRIBUTE_BY_ID_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Get Attributes (Paginated)
export const getAttributes = (queryParams) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_GET_ATTRIBUTES });
    const response = await attributeService.getAttributes(queryParams);
    dispatch({
      type: attributeActionTypes.GET_ATTRIBUTES_SUCCESS,
      data: response.data.payload,
    });
    return response.data?.payload; // Return server response
  } catch (ex) {
    console.error("Get attributes error:", ex.message);
    dispatch({ type: attributeActionTypes.GET_ATTRIBUTES_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Add Attribute Value
export const addAttributeValue = (valueData) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_ADD_ATTRIBUTE_VALUE });
    const response = await attributeService.addAttributeValue(valueData);
    dispatch({
      type: attributeActionTypes.ADD_ATTRIBUTE_VALUE_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Add attribute value error:", ex.message);
    dispatch({ type: attributeActionTypes.ADD_ATTRIBUTE_VALUE_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Update Attribute Value
export const updateAttributeValue = (valueData) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_UPDATE_ATTRIBUTE_VALUE });
    const response = await attributeService.updateAttributeValue(valueData);
    dispatch({
      type: attributeActionTypes.UPDATE_ATTRIBUTE_VALUE_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Update attribute value error:", ex.message);
    dispatch({ type: attributeActionTypes.UPDATE_ATTRIBUTE_VALUE_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Delete Attribute Value
export const deleteAttributeValue = (valueId) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_DELETE_ATTRIBUTE_VALUE });
    const response = await attributeService.deleteAttributeValue(valueId);
    dispatch({ type: attributeActionTypes.DELETE_ATTRIBUTE_VALUE_SUCCESS });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Delete attribute value error:", ex.message);
    dispatch({ type: attributeActionTypes.DELETE_ATTRIBUTE_VALUE_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Get Attribute Values
export const getAttributeValues = (attributeId) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_GET_ATTRIBUTE_VALUES });
    const response = await attributeService.getAttributeValues(attributeId);
    dispatch({
      type: attributeActionTypes.GET_ATTRIBUTE_VALUES_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Get attribute values error:", ex.message);
    dispatch({ type: attributeActionTypes.GET_ATTRIBUTE_VALUES_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

export const connectAttributeWithProduct = (productId, attributeId, attributeValueId) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_CONNECT_ATTRIBUTE_WITH_PRODUCT });
    const response = await attributeService.connectAttributeWithProduct(productId, attributeId, attributeValueId);
    dispatch({
      type: attributeActionTypes.CONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS,
      data: response.data.payload,
    });
    return response.data;
  } catch (ex) {
    console.error("Connect attribute with product error:", ex.message);
    dispatch({ type: attributeActionTypes.CONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE, error: ex.response?.data });
    throw ex.response?.data;
  }
};

export const disconnectAttributeWithProduct = (productId, attributeId, attributeValueId) => async (dispatch) => {
  try {
    dispatch({ type: attributeActionTypes.START_DISCONNECT_ATTRIBUTE_WITH_PRODUCT });
    const response = await attributeService.disconnectAttributeWithProduct(productId, attributeId, attributeValueId);
    dispatch({
      type: attributeActionTypes.DISCONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS,
      data: response.data.payload,
    });
    return response.data;
  } catch (ex) {
    console.error("Disconnect attribute with product error:", ex.message);
    dispatch({ type: attributeActionTypes.DISCONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE, error: ex.response?.data });
    throw ex.response?.data;
  }
};

const attributeActions = {
  addAttribute,
  updateAttribute,
  deleteAttribute,
  getAttributeById,
  getAttributes,
  addAttributeValue,
  updateAttributeValue,
  deleteAttributeValue,
  getAttributeValues,
  connectAttributeWithProduct,
  disconnectAttributeWithProduct,
};

export default attributeActions;
