import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import usePagination from "../../../../hooks/usePagination";
import productActions from "../../../../store/actions/products/product.actions";
import LoaderComponent from "../../../../components/elements/LoaderComponent";
import TextInputElementComponent from "../../../../components/elements/inputElements/TextInputElementComponent";
import textInputModel from "../../../../models/elements/inputElements/textInput.model";
import TableElementComponent from "../../../../components/elements/TableElementComponent";
import AlertService from "../../../../services/alerts/alert.service";

const buttonsActions = { delete: "delete", edit: "edit", gallery: "gallery", attributes: "attributes" };

const AdminProductsListPageComponent = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  const {
    items: products,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = usePagination({
    queryKey: ["admin-products", searchQuery],
    fetchFunction: async (pageParam) => {
      const params = {
        pageNumber: pageParam,
        pageSize: 10,
        searchTerm: searchQuery,
      };
      return await dispatch(productActions.getProducts(params));
    },
  });

  useEffect(() => {
    if (!searchParams.get("d")) {
      refetch();
    }
  }, [searchParams]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleActionClick = (action, productId) => {
    const queryParams = new URLSearchParams();
    queryParams.set("d", "product");
    queryParams.set("id", productId);

    switch (action) {
      case buttonsActions.delete:
        handleDeleteProduct(productId);
        return;
      case buttonsActions.edit:
        queryParams.set("action", "update");
        // handleEditProduct(productId);
        break;
      case buttonsActions.gallery:
        queryParams.set("action", "gallery");
        break;
      case buttonsActions.attributes:
        queryParams.set("action", "attributes");
        break;
      default:
        break;
    }

    setSearchParams(queryParams);
  };

  const handleDeleteProduct = async (productId) => {
    const product = products.find((p) => p.id === productId);
    const { id, productName } = product;

    const isConfirmed = await AlertService.confirm("מחיקת מוצר", `האם אתה בטוח שברצונך למחוק את המוצר "${productName}"?`, "כן, מחק", "ביטול");

    if (isConfirmed) {
      try {
        await dispatch(productActions.deleteProduct(id));
        AlertService.success("המוצר נמחק", `המוצר "${productName}" נמחק בהצלחה.`);
        refetch();
      } catch (error) {
        AlertService.error("שגיאה במחיקה", "אירעה שגיאה בעת מחיקת המוצר. נסה שוב מאוחר יותר.");
      }
    }
  };

  const getProductsTable = () => {
    const columns = [
      {
        label: "תמונה",
        field: "primaryImageId",
        render: (value, row) => (
          <div className="imageColumnContainer">
            <img src={row.images?.find((img) => img.id === value)?.url || "https://via.placeholder.com/50"} alt={row.productName} />
          </div>
        ),
      },
      { label: "שם מוצר", field: "productName" },
      {
        label: "זמין באתר",
        field: "isAvailable",
        render: (value) => <span>{value ? "כן" : "לא"}</span>,
      },
      { label: "כמות במלאי", field: "stockQuantity" },
      { label: "כמות מוזמנת", field: "reservedQuantity" },
      {
        label: "מחיר",
        field: "price",
        render: (value) => <span>{value ? `${value}` : "לא נקבע"}</span>,
      },
      {
        label: "מחיר מבצע",
        field: "salePrice",
        render: (value) => <span>{value ? `${value}` : "לא נקבע"}</span>,
      },
    ];

    const actions = [
      {
        label: "עריכה",
        icon: "fa-solid fa-edit",
        className: "greenButton",
        onClick: (product) => handleActionClick(buttonsActions.edit, product.id),
      },
      {
        label: "עריכת תמונות",
        icon: "fa-regular fa-image",
        className: "greenButton",
        onClick: (product) => handleActionClick(buttonsActions.gallery, product.id),
      },
      {
        label: "עריכת מאפיינים",
        icon: "fa-solid fa-list-ul",
        className: "greenButton",
        onClick: (product) => handleActionClick(buttonsActions.attributes, product.id),
      },
      {
        label: "מחיקת מוצר",
        icon: "fa-solid fa-trash",
        className: "redButton",
        onClick: (product) => handleActionClick(buttonsActions.delete, product.id),
      },
    ];

    return <TableElementComponent columns={columns} data={products} actions={actions} />;
  };

  const getNoProductsElement = () => (
    <div className="noItemsContainer">
      <h2>לא נמצאו מוצרים</h2>
      <p>נסה לשנות את הקריטריונים לחיפוש או לבדוק מוצרים קיימים.</p>
    </div>
  );

  const getSearchInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "searchInput",
      value: searchQuery,
      placeholder: "חפש מוצר",
      onChange: handleSearch,
      icon: <i className="fa-solid fa-magnifying-glass"></i>,
    };

    return <TextInputElementComponent props={inputProps} />;
  };

  const getNewProductContainerElement = () => {
    return (
      <div className="newProductContainer">
        <Link to="?d=product&action=add" className="addProductButton primaryButton">
          הוסף מוצר
        </Link>
      </div>
    );
  };

  return (
    <div className="adminProductListContainer">
      <h1>ניהול מוצרים</h1>
      <div className="searchContainer">
        {getSearchInputElement()} {getNewProductContainerElement()}
      </div>

      {products?.length > 0 ? (
        <InfiniteScroll dataLength={products.length} next={fetchNextPage} hasMore={hasNextPage} loader={<LoaderComponent />}>
          <div className="productListContainer">{getProductsTable()}</div>
        </InfiniteScroll>
      ) : (
        getNoProductsElement()
      )}
    </div>
  );
};

export default AdminProductsListPageComponent;
