import { useEffect, useState } from "react";
import validationHelper from "../../helpers/validation.helper";
import productActions from "../../store/actions/products/product.actions";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AlertService from "../../services/alerts/alert.service";

import "../../styles/pages/products/productDetailsPageComponent.scss";
import SwiperThumbnailSliderGalleryComponent from "../../components/elements/swiper/SwiperThumbnailSliderGalleryComponent";
import categoryActions from "../../store/actions/products/categories/category.actions";
import ProductDetailsShoppingCartButtonsComponent from "../../components/products/productDetailsPageComponents/ProductDetailsShoppingCartButtonsComponent";

export const ProductDetailsPageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    if (productId) {
      fetchProductDetails();
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      fetchCategoryDetails();
    }
  }, [product?.categoryID]);

  const fetchProductDetails = async () => {
    try {
      const response = await dispatch(productActions.getProductById(productId));

      if (!validationHelper.validateHttpResponse(response, true)) {
        AlertService.error("שגיאה בטעינת פרטי המוצר", "אנא נסה שוב מאוחר יותר");
        navigate(-1);
        return;
      }

      setProduct(response.payload);
    } catch (error) {
      AlertService.error("שגיאה בטעינת פרטי המוצר", "אנא נסה שוב מאוחר יותר");
      navigate(-1);
      console.error("Error fetching product details:", error);
    }
  };

  const fetchCategoryDetails = async () => {
    try {
      const response = await dispatch(categoryActions.getCategoryById(product.categoryID));
      if (!validationHelper.validateHttpResponse(response, true)) return;
      setCategory(response.payload);
    } catch (error) {
      console.error("Error fetching category details:", error);
    }
  };

  const getImagesGalleryElement = () => {
    const images = product.images.map((image, idx) => ({ src: image.url, alt: `${product.productName} ${idx + 1}` }));
    return <SwiperThumbnailSliderGalleryComponent images={images} />;
  };

  const getNavigationTreeElement = () => {
    const navigationLinks = [{ to: "/", label: "עמוד הבית" }, product.categoryID && category?.categoryName ? { to: `/categories/${product.categoryID}`, label: category.categoryName } : null, { label: product.productName }].filter(Boolean); // Removes any `null` or `undefined` values

    return (
      <div className="navigationLinkContainer">
        {navigationLinks.map((link, index) => (
          <span key={index}>
            {link.to ? <Link to={link.to}>{link.label}</Link> : <span>{link.label}</span>}
            {index < navigationLinks.length - 1 && " / "}
          </span>
        ))}
      </div>
    );
  };

  const getProductAttributesElement = () => {
    if (!product.attributes || product.attributes.length === 0) return null;

    return (
      <div className="attributesContainer">
        {product.attributes.map((attribute) => (
          <label key={attribute.id}>
            <span className="bold">{attribute.name}</span>:<span> {attribute.attributeValues.map((value) => value.value).join(", ")} </span>
          </label>
        ))}
      </div>
    );
  };

  if (!product || !product.images) return null;
  return (
    <div className="productDetailsContainer">
      <div className="productDetailsHeaderContentContainer">
        {getImagesGalleryElement()}
        <div className="productMainContentContainer">
          {getNavigationTreeElement()}
          <h1>{product.productName}</h1>
          {getProductAttributesElement()}
          <h3>₪{product.price}</h3>

          <ProductDetailsShoppingCartButtonsComponent product={product} />

          <p className="productDescription">{product.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsPageComponent;
