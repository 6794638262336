export const productModel = {
  id: null,
  productName: "",
  description: "",
  displayOrder: 999,

  // Stock & Availability
  isAvailable: false,
  status: "Draft",
  stockQuantity: 0,
  reservedQuantity: 0,

  // Pricing & Discounts
  price: null,
  discountedPrice: null,
  salePrice: null,
  bulkDiscount: null,
  bulkDiscountThreshold: null,

  // Inventory Management
  sku: "",
  barcode: "",

  // Weight & Dimensions
  weight: null,
  width: null,
  height: null,
  depth: null,

  // Costs & Profit Tracking (Included on Add, but NOT in DTO)
  baseCost: null,
  packagingCost: null,
  shippingCost: null,
  handlingCost: null,
  importCost: null,

  // Category & Relations
  categoryID: null,
  primaryImageId: null,
  images: [],
};
