import useShoppingCart from "../../hooks/useShoppingCart.hook";
import "../../styles/components/shoppingCart/shoppingCartGridItemsTableComponent.scss";
import ProductDetailsShoppingCartButtonsComponent from "../products/productDetailsPageComponents/ProductDetailsShoppingCartButtonsComponent";

export const ShoppingCartGridItemsTableComponent = () => {
  const { cartItems, removeItem, updateItemQuantity } = useShoppingCart();

  const getTableTitlesElement = () => {
    return (
      <div className="tableTitlesContainer">
        <label>פריט</label>
        <label>כמות</label>
        <label>סה"כ</label>
      </div>
    );
  };

  const getTableItemsContentElement = (item) => {
    return (
      <div className="tableItemsContentContainer">
        {cartItems.map((cartItem) => (
          <div className="cartItemContainer" key={cartItem.id}>
            <div className="cartItemDetailsContainer">
              <div className="itemImageContainer">
                <img src={cartItem.image} alt={cartItem.name} />
              </div>
              <div className="itemContentContainer">
                <span>{cartItem.name}</span>
                <span>{cartItem.price}</span>
              </div>
            </div>
            <div className="cartItemQuantityContainer">
              <ProductDetailsShoppingCartButtonsComponent product={cartItem} showButton={false} />
            </div>
            <div className="cartItemPriceContainer">{cartItem.price * cartItem.quantity}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="shoppingCartGridItemsTableContainer">
      {getTableTitlesElement()}
      {getTableItemsContentElement()}
    </div>
  );
};

export default ShoppingCartGridItemsTableComponent;
