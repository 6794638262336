import { useState } from "react";
import "../../../styles/components/elements/inputElements/textInputElementComponent.scss";

export const TextInputElementComponent = ({ props }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    clearDesign,
    customDesignClass = "",
    placeholder,
    type,
    error,
    icon,
    min = 0,
    style = {}, // Inline custom styles
    customInputClass = "", // Additional custom input class
  } = props;

  // State to toggle password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Determine the input type (toggle between "password" and "text")
  const inputType = type === "password" && isPasswordVisible ? "text" : type;

  return (
    <div
      className={`${clearDesign ? "" : "textElementContainer"} ${customDesignClass} defaultInput`}
      style={style} // Apply custom styles to the container
    >
      {label && <label>{label}</label>}
      <div className="inputWrapper">
        <input
          name={name}
          type={inputType}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
          min={min}
          className={`defaultInputStyle ${customInputClass} `} // Apply additional custom input class
        />
        {type === "password" && (
          <span className="icon" onClick={() => setIsPasswordVisible((prev) => !prev)}>
            {isPasswordVisible ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
          </span>
        )}
        {icon && <span className="icon">{icon}</span>}
      </div>
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default TextInputElementComponent;
