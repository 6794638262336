import { useState } from "react";
import shoppingCartHelper from "../../../helpers/shoppingCart.helper";
import useShoppingCart from "../../../hooks/useShoppingCart.hook";

import "../../../styles/components/products/productDetailsPageComponents/productDetailsShoppingCartButtonsComponent.scss";

export const ProductDetailsShoppingCartButtonsComponent = ({ product, showButton = true }) => {
  const { cartItems, addItem, updateItemQuantity } = useShoppingCart();
  const cartItem = cartItems.find((item) => item.id === product.id);
  const initialQuantity = showButton ? 1 : cartItem.quantity;
  const [quantity, setQuantity] = useState(initialQuantity); // Always start at 1

  const updateCart = (newQuantity) => {
    if (cartItem) {
      updateItemQuantity(product.id, newQuantity);
    } else {
      addItem(shoppingCartHelper.mapProductToCartItem({ ...product, quantity: newQuantity }));
    }
  };

  const handleIncrease = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    if (!showButton) updateCart(newQuantity); // If no button, update cart immediately
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      if (!showButton) updateCart(newQuantity); // If no button, update cart immediately
    }
  };

  const handleAddToCart = () => {
    updateCart(cartItem ? cartItem.quantity + quantity : quantity);
    setQuantity(initialQuantity); // Reset quantity after adding
  };

  return (
    <div className="productDetailsShoppingCartButtonsContainer">
      <div className="quantityControls">
        <button className="quantityButton" onClick={handleDecrease}>
          -
        </button>
        <button className="quantityButton quantityDisplay">{quantity}</button>
        <button className="quantityButton" onClick={handleIncrease}>
          +
        </button>
      </div>
      {showButton && (
        <button className="primaryButton" onClick={handleAddToCart}>
          הוסף לעגלה
        </button>
      )}
    </div>
  );
};

export default ProductDetailsShoppingCartButtonsComponent;

// import { useState } from "react";
// import shoppingCartHelper from "../../../helpers/shoppingCart.helper";
// import useShoppingCart from "../../../hooks/useShoppingCart.hook";

// import "../../../styles/components/products/productDetailsPageComponents/productDetailsShoppingCartButtonsComponent.scss";

// export const ProductDetailsShoppingCartButtonsComponent = ({ product }) => {
//   const { cartItems, addItem, updateItemQuantity } = useShoppingCart();
//   const cartItem = cartItems.find((item) => item.id === product.id);
//   const [quantity, setQuantity] = useState(1); // Always start at 1

//   const handleIncrease = () => {
//     setQuantity((prev) => prev + 1);
//   };

//   const handleDecrease = () => {
//     if (quantity > 1) {
//       setQuantity((prev) => prev - 1);
//     }
//   };

//   const handleAddToCart = () => {
//     if (cartItem) {
//       updateItemQuantity(product.id, cartItem.quantity + quantity);
//     } else {
//       addItem(shoppingCartHelper.mapProductToCartItem({ ...product, quantity }));
//     }
//     setQuantity(1); // Reset quantity after adding
//   };

//   return (
//     <div className="productDetailsShoppingCartButtonsContainer">
//       <div className="quantityControls">
//         <button className="quantityButton" onClick={handleDecrease}>
//           -
//         </button>
//         <button className="quantityButton quantityDisplay">{quantity}</button>
//         <button className="quantityButton" onClick={handleIncrease}>
//           +
//         </button>
//       </div>
//       <button className="primaryButton" onClick={handleAddToCart}>
//         הוסף לעגלה
//       </button>
//     </div>
//   );
// };

// export default ProductDetailsShoppingCartButtonsComponent;
