import { appHttpClient } from "../../installations/http/axiosInterfaces";

export const addProduct = (data) => {
    return appHttpClient.post(`/Product/Add`, data);
};

export const updateProduct = (data) => {
    return appHttpClient.patch(`/Product/Update`, data);
};

export const deleteProduct = (productId) => {
    return appHttpClient.delete(`/Product/Delete/${productId}`);
};

export const getProductById = (productId) => {
    return appHttpClient.get(`/Product/${productId}`);
};

export const getProducts = (queryParams) => {
    return appHttpClient.get(`/Product/GetProducts`, { params: queryParams });
};

export const addProductImage = (productId, file, isPrimary) => {
    const formData = new FormData();
    formData.append("file", file);
    return appHttpClient.patch(`/Product/Update/Image/${productId}/${isPrimary}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Inform the server that this is form data
        },
      });
};

export const deleteProductImage = (productId, imageId) => {
    return appHttpClient.delete(`/Product/Delete/Image/${productId}/${imageId}`);
};

export const setImageAsPrimary = (productId, imageId) => {
    return appHttpClient.delete(`/Product/Update/Image/Primary/Set/${productId}/${imageId}`);
};

const productService = {
    addProduct,
    updateProduct,
    deleteProduct,
    getProductById,
    getProducts,
    addProductImage,
    deleteProductImage,
    setImageAsPrimary,
};

export default productService;
