import categoryService from "../../../../services/products/categories/category.service";
import { categoryActionTypes } from "../../../actionTypes/products/categories/category.actionTypes";

// Add Category
export const addCategory = (categoryData) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_ADD_CATEGORY });
    const response = await categoryService.addCategory(categoryData);
    dispatch({
      type: categoryActionTypes.ADD_CATEGORY_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Add category error:", ex.message);
    dispatch({ type: categoryActionTypes.ADD_CATEGORY_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Update Category
export const updateCategory = (categoryData) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_UPDATE_CATEGORY });
    const response = await categoryService.updateCategory(categoryData);
    dispatch({
      type: categoryActionTypes.UPDATE_CATEGORY_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Update category error:", ex.message);
    dispatch({ type: categoryActionTypes.UPDATE_CATEGORY_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

export const updateCategoryImage = (categoryId, data) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_UPDATE_CATEGORY_IMAGE });
    const response = await categoryService.updateCategoryImage(categoryId, data);
    dispatch({
      type: categoryActionTypes.UPDATE_CATEGORY_IMAGE_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Update category image error:", ex.message);
    dispatch({ type: categoryActionTypes.UPDATE_CATEGORY_IMAGE_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Delete Category
export const deleteCategory = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_DELETE_CATEGORY });
    const response = await categoryService.deleteCategory(categoryId);
    dispatch({ type: categoryActionTypes.DELETE_CATEGORY_SUCCESS });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Delete category error:", ex.message);
    dispatch({ type: categoryActionTypes.DELETE_CATEGORY_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Get Category by ID
export const getCategoryById = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_GET_CATEGORY_BY_ID });
    const response = await categoryService.getCategoryById(categoryId);
    dispatch({
      type: categoryActionTypes.GET_CATEGORY_BY_ID_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Get category by ID error:", ex.message);
    dispatch({ type: categoryActionTypes.GET_CATEGORY_BY_ID_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Get Categories (Paginated)
export const getCategories = (queryParams) => async (dispatch) => {
  try {
    dispatch({ type: categoryActionTypes.START_GET_CATEGORIES });
    const response = await categoryService.getCategories(queryParams);
    dispatch({
      type: categoryActionTypes.GET_CATEGORIES_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Get categories error:", ex.message);
    dispatch({ type: categoryActionTypes.GET_CATEGORIES_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

const categoryActions = {
  addCategory,
  updateCategory,
  updateCategoryImage,
  deleteCategory,
  getCategoryById,
  getCategories,
};

export default categoryActions;
