import { useEffect, useState } from "react";
import "../../../styles/components/products/productCardComponent.scss";
import { useNavigate } from "react-router-dom";

export const ProductCardComponent = ({ product }) => {
  const navigate = useNavigate();
  const [primaryImageUrl, setPrimaryImageUrl] = useState("");

  useEffect(() => {
    if (product && product.primaryImageId) {
      const imageDetails = product.images.find((image) => image.id === product.primaryImageId);
      if (imageDetails) setPrimaryImageUrl(imageDetails.url);
    } else if (product) {
      setPrimaryImageUrl(product.images[0]?.url);
    }
  }, [product]);

  const navigateToMoreDetails = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <div className="productCardContainer">
      <div className="productImageContainer">
        <img src={primaryImageUrl} alt={product.productName} />
      </div>
      <div className="productContentContainer">
        <h3>{product.productName}</h3>
        <h4>{product.price}</h4>
      </div>
      <button className="primaryButton" onClick={navigateToMoreDetails}>
        פרטים נוספים
      </button>
    </div>
  );
};

export default ProductCardComponent;
