import { MoonLoader, GridLoader } from 'react-spinners'
import useMobileScreen from '../../hooks/useMobileScreen';

const LoaderComponent = () => {
    const {isMobileScreen} = useMobileScreen();
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <GridLoader
        color="#708D57"
        size={isMobileScreen ? 25 : 50}
        speedMultiplier={0.6}
      />
    </div>
  )
}

export default LoaderComponent
