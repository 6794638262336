export const categoryActionTypes = {
    START_ADD_CATEGORY: "START_ADD_CATEGORY",
    ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
    ADD_CATEGORY_FAILURE: "ADD_CATEGORY_FAILURE",

    START_UPDATE_CATEGORY: "START_UPDATE_CATEGORY",
    UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
    UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",
   
    START_UPDATE_CATEGORY_IMAGE: "START_UPDATE_CATEGORY_IMAGE",
    UPDATE_CATEGORY_IMAGE_SUCCESS: "UPDATE_CATEGORY_IMAGE_SUCCESS",
    UPDATE_CATEGORY_IMAGE_FAILURE: "UPDATE_CATEGORY_IMAGE_FAILURE",

    START_DELETE_CATEGORY: "START_DELETE_CATEGORY",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",

    START_GET_CATEGORY_BY_ID: "START_GET_CATEGORY_BY_ID",
    GET_CATEGORY_BY_ID_SUCCESS: "GET_CATEGORY_BY_ID_SUCCESS",
    GET_CATEGORY_BY_ID_FAILURE: "GET_CATEGORY_BY_ID_FAILURE",

    START_GET_CATEGORIES: "START_GET_CATEGORIES",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
};