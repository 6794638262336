import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./reducers/accounts/account.reducer";
import categoryReducer from "./reducers/products/categories/category.reducer";
import attributeReducer from "./reducers/products/attributes/attribute.refucer";
import productReducer from "./reducers/products/product.reducer";
import shoppingCartReducer from "./slices/shoppingCart/shoppingCart.slice";

const store = configureStore({
  reducer: {
    accountReducer: accountReducer, // Use a key to identify this slice of the state
    categoryReducer: categoryReducer, // Use a key to identify this slice of the state
    attributeReducer: attributeReducer, // Use a key to identify this slice of the state
    productReducer: productReducer, // Use a key to identify this slice of the state
    shoppingCart: shoppingCartReducer,
  },
});

export default store;
