const validateHttpResponse = (response, validateEmptyPayload = false) => {
  if (!response) return false;
  if (response.statusCode !== 200) return false;
  if (validateEmptyPayload && !response.payload) return false;
  return true;
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePassword = (password) => {
  return password.length >= 6; // Validate only the length of the password
};

const validateText = (text, allowSpaces = false) => {
  if (typeof text !== "string") return false;
  const textRegex = allowSpaces ? /^[a-zA-Zא-ת\s]+$/ : /^[a-zA-Zא-ת]+$/;
  return textRegex.test(text);
};

const validationHelper = {
  validateHttpResponse,
  validateEmail,
  validatePassword,
  validateText,
};
export default validationHelper;
