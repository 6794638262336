import productService from "../../../services/products/product.service";
import { productActionTypes } from "../../actionTypes/product.actionTypes";

export const addProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_ADD_PRODUCT });
    const response = await productService.addProduct(productData);
    dispatch({
      type: productActionTypes.ADD_PRODUCT_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Add product error:", ex.message);
    dispatch({ type: productActionTypes.ADD_PRODUCT_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

export const updateProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_UPDATE_PRODUCT });
    const response = await productService.updateProduct(productData);
    dispatch({
      type: productActionTypes.UPDATE_PRODUCT_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Update product error:", ex.message);
    dispatch({ type: productActionTypes.UPDATE_PRODUCT_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

export const deleteProduct = (productId) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_DELETE_PRODUCT });
    const response = await productService.deleteProduct(productId);
    dispatch({ type: productActionTypes.DELETE_PRODUCT_SUCCESS });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Delete product error:", ex.message);
    dispatch({ type: productActionTypes.DELETE_PRODUCT_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

export const getProductById = (productId) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_GET_PRODUCT });
    const response = await productService.getProductById(productId);
    dispatch({
      type: productActionTypes.GET_PRODUCT_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Get product by ID error:", ex.message);
    dispatch({ type: productActionTypes.GET_PRODUCT_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

export const getProducts = (queryParams) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_GET_PRODUCTS });
    const response = await productService.getProducts(queryParams);
    dispatch({
      type: productActionTypes.GET_PRODUCTS_SUCCESS,
      data: response.data.payload,
    });
    return response.data; // Return server response
  } catch (ex) {
    console.error("Get products error:", ex.message);
    dispatch({ type: productActionTypes.GET_PRODUCTS_FAILURE, error: ex.response?.data });
    throw ex.response?.data; // Return error
  }
};

// Add Product Image
export const addProductImage =
  (productId, imageFile, isPrimary = false) =>
  async (dispatch) => {
    try {
      dispatch({ type: productActionTypes.START_ADD_PRODUCT_IMAGE });
      const response = await productService.addProductImage(productId, imageFile, isPrimary);
      dispatch({
        type: productActionTypes.ADD_PRODUCT_IMAGE_SUCCESS,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({ type: productActionTypes.ADD_PRODUCT_IMAGE_FAILURE, error: error.response?.data });
      throw error.response?.data;
    }
  };

// Delete Product Image
export const deleteProductImage = (productId, imageId) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_DELETE_PRODUCT_IMAGE });
    await productService.deleteProductImage(productId, imageId);
    dispatch({
      type: productActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS,
      data: imageId,
    });
  } catch (error) {
    dispatch({ type: productActionTypes.DELETE_PRODUCT_IMAGE_FAILURE, error: error.response?.data });
    throw error.response?.data;
  }
};

// Set Image as Primary
export const setImageAsPrimary = (productId, imageId) => async (dispatch) => {
  try {
    dispatch({ type: productActionTypes.START_SET_IMAGE_AS_PRIMARY });
    await productService.setImageAsPrimary(productId, imageId);
    dispatch({
      type: productActionTypes.SET_IMAGE_AS_PRIMARY_SUCCESS,
      data: imageId,
    });
  } catch (error) {
    dispatch({ type: productActionTypes.SET_IMAGE_AS_PRIMARY_FAILURE, error: error.response?.data });
    throw error.response?.data;
  }
};

const productActions = {
  addProduct,
  updateProduct,
  deleteProduct,
  getProductById,
  getProducts,
  addProductImage,
  deleteProductImage,
  setImageAsPrimary,
};

export default productActions;
