import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { categoryModel } from "../../../../models/products/categories/category.model";
import validationHelper from "../../../../helpers/validation.helper";
import AlertService from "../../../../services/alerts/alert.service";
import textInputModel from "../../../../models/elements/inputElements/textInput.model";
import TextInputElementComponent from "../../../elements/inputElements/TextInputElementComponent";
import TextAreaInputElementComponent from "../../../elements/inputElements/TextAreaInputElementComponent";
import categoryActions from "../../../../store/actions/products/categories/category.actions";
import SelectInputElementComponent from "../../../elements/inputElements/SelectInputElementComponent";

const AddOrUpdateCategoryDialogComponent = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const categories = useSelector((state) => state.categoryReducer.categories.items);

  const [categoryDetails, setCategoryDetails] = useState(categoryModel);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});

  const actionType = searchParams.get("action");
  const categoryId = searchParams.get("id");

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (actionType === "update" && categoryId) {
      fetchCategoryDetails();
    }
  }, [actionType, categoryId]);

  const fetchCategoryDetails = async () => {
    try {
      const response = await dispatch(categoryActions.getCategoryById(categoryId));

      if (!validationHelper.validateHttpResponse(response, true)) {
        AlertService.error("שגיאה בטעינת פרטי הקטגוריה", "אנא נסה שוב מאוחר יותר");
        return;
      }

      setCategoryDetails(response.payload);
    } catch (error) {
      AlertService.error("שגיאה בטעינת פרטי הקטגוריה", "אנא נסה שוב מאוחר יותר");
      console.error("Error fetching category details:", error);
    }
  };

  const fetchCategories = async () => {
    const queryParams = { getAll: true };
    try {
      await dispatch(categoryActions.getCategories(queryParams));
    } catch (error) {
      console.error("Error fetching item groups:", error);
    }
  };

  // ********** Input Change Event ********
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryDetails({ ...categoryDetails, [name]: value });
  };

  // ********** Submit Event ********
  const validateForm = () => {
    const newErrors = {};

    if (!categoryDetails.categoryName || categoryDetails.categoryName.trim() === "") {
      newErrors.categoryName = "שם הקטגוריה הוא שדה חובה";
    } else if (!validationHelper.validateText(categoryDetails.categoryName, true)) {
      newErrors.categoryName = "שם הקטגוריה חייב להכיל אותיות בלבד";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    try {
      const request = !categoryDetails.parentCategoryID ? { ...categoryDetails, parentCategoryID: null } : categoryDetails;

      if (actionType === "update") await handleUpdateCategory(request);
      else await handleAddCategory(request);
    } catch (error) {
      setErrors({ general: actionType === "update" ? "שגיאה בעדכון הקטגוריה. נסה שוב מאוחר יותר." : "שגיאה בשרת. נסה שוב מאוחר יותר." });
    }
  };

  const handleClearSearchParams = () => {
    setSearchParams(new URLSearchParams()); // Clears all search parameters
  };

  const handleAddCategory = async (request) => {
    const response = await dispatch(categoryActions.addCategory(request));
    if (!validationHelper.validateHttpResponse(response, false)) {
      setErrors({ general: "הוספת קטגוריה נכשלה. נסה שוב." });
      return;
    }
    AlertService.success("קטגוריה נוספה בהצלחה");
    handleClearSearchParams();
  };

  const handleUpdateCategory = async (request) => {
    const response = await dispatch(categoryActions.updateCategory(request));
    if (!validationHelper.validateHttpResponse(response, false)) {
      setErrors({ general: "עדכון הקטגוריה נכשל" });
      return;
    }
    AlertService.success("הקטגוריה עודכנה בהצלחה");
    handleClearSearchParams();
  };

  const handleUpdateImage = async () => {
    if (!imageFile || !categoryId) {
      AlertService.error("אנא בחר תמונה ולאחר מכן נסה שוב.");
      return;
    }

    const formData = new FormData();
    formData.append("file", imageFile);

    try {
      const response = await dispatch(categoryActions.updateCategoryImage(categoryId, formData));

      if (!validationHelper.validateHttpResponse(response)) {
        AlertService.error("שגיאה בעדכון התמונה", "אנא נסה שוב מאוחר יותר");
        return;
      }

      AlertService.success("התמונה עודכנה בהצלחה");
      fetchCategoryDetails();
    } catch (error) {
      AlertService.error("שגיאה בעדכון התמונה", "אנא נסה שוב מאוחר יותר");
      console.error("Update category image error:", error);
    }
  };

  // ********** Get Elements ********
  const getCategoryNameInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "categoryName",
      error: errors.categoryName,
      value: categoryDetails.categoryName,
      onChange: handleInputChange,
      label: "שם הקטגוריה",
    };
    return <TextInputElementComponent props={inputProps} />;
  };

  const getCategoryDisplayOrderInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "displayOrder",
      error: errors.displayOrder,
      value: categoryDetails.displayOrder,
      onChange: handleInputChange,
      label: "סדר תצוגה",
      type: "number",
    };
    return <TextInputElementComponent props={inputProps} />;
  };

  const getCategoryDescriptionInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "description",
      error: errors.description,
      value: categoryDetails.description,
      onChange: handleInputChange,
      label: "תיאור הקטגוריה",
    };
    return <TextAreaInputElementComponent props={inputProps} />;
  };

  const getCategoryImageInputElement = () => {
    if (actionType === "add") return null;

    return (
      <div className="dialogContainer imageUploadContainer">
        <label>עדכן תמונה:</label>
        <input type="file" accept="image/*" onChange={handleImageChange} />
        <button type="button" className="secondaryButton" onClick={handleUpdateImage}>
          עדכון תמונה
        </button>
      </div>
    );
  };

  const getParentsCategoriesSelectElement = () => {
    const parentCategoriesOptions = categories?.filter((category) => !category.parentCategoryID).map((category) => ({ value: category.id, label: category.categoryName }));

    const inputProps = {
      name: "parentCategoryID",
      value: categoryDetails.parentCategoryID,
      options: parentCategoriesOptions,
      onChange: handleInputChange,
      searchable: true, // Enable search
    };
    return <SelectInputElementComponent props={inputProps} />;
  };

  return (
    <div className="addCategoryDialogContainer">
      <h1>{actionType === "update" ? "עדכון קטגוריה" : "הוסף קטגוריה"}</h1>
      <form onSubmit={handleSubmit}>
        {getCategoryNameInputElement()}
        {getParentsCategoriesSelectElement()}
        {getCategoryDescriptionInputElement()}
        {getCategoryDisplayOrderInputElement()}
        {getCategoryImageInputElement()}

        <button type="submit" className="primaryButton">
          {actionType === "update" ? "עדכון" : "הוסף"}
        </button>
        {errors.general && <span className="error">{errors.general}</span>}
      </form>
    </div>
  );
};

export default AddOrUpdateCategoryDialogComponent;
