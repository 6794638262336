import { accountActionTypes } from "../../actionTypes/accounts/account.actionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  token: localStorage.getItem("jwtToken") || null,
  isAuthenticated: !!localStorage.getItem("jwtToken"),
  userInfo: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActionTypes.START_LOGIN:
    case accountActionTypes.START_REGISTER:
    case accountActionTypes.START_GET_USER_INFO:
    case accountActionTypes.START_RESET_USER_PASSWORD:
    case accountActionTypes.START_CHANGE_USER_PASSWORD:
      return setStateLoading(state, action);

    case accountActionTypes.LOGIN_SUCCESS:
    case accountActionTypes.REGISTER_SUCCESS:
    case accountActionTypes.RESET_USER_PASSWORD_SUCCESS:
    case accountActionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return setLoginSuccess(state, action);

    case accountActionTypes.GET_USER_INFO_SUCCESS:
      return setUserInfoSuccess(state, action);

    case accountActionTypes.LOGIN_FAILURE:
    case accountActionTypes.REGISTER_FAILURE:
    case accountActionTypes.RESET_USER_PASSWORD_FAILURE:
    case accountActionTypes.GET_USER_INFO_FAILURE:
    case accountActionTypes.CHANGE_USER_PASSWORD_FAILURE:
      return setStateFailure(state, action);

    case accountActionTypes.LOGOUT:
      return setStateLogout(state, action);

    default:
      return state;
  }
};

const setStateLoading = (state) => {
  return {
    ...state,
    stateInfo: {
      isLoading: true,
      isSuccess: false,
      isError: false,
      error: null,
    },
  };
};

const setStateFailure = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: false,
      isError: true,
      error: action.data,
    },
  };
};

const setStateSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
  };
};

const setLoginSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    token: action.data,
    isAuthenticated: true,
  };
};

const setStateLogout = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    token: null,
    isAuthenticated: false,
  };
};

const setUserInfoSuccess = (state, action) => {
  return {
    ...state,
    stateInfo: {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
    },
    userInfo: action.data,
  };
};

export default accountReducer;
