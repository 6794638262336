import { useInfiniteQuery } from "@tanstack/react-query";

const usePagination = ({ queryKey, fetchFunction, options = {} }) => {
  const { data, fetchNextPage, hasNextPage, isLoading, isError, error, refetch } = useInfiniteQuery({
    queryKey: Array.isArray(queryKey) ? queryKey : [queryKey],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await fetchFunction(pageParam);
      if (!response || !response.payload) {
        throw new Error("Invalid response format from fetchFunction");
      }
      return response.payload;
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    ...options,
  });

  // Flatten the items from all pages
  const items = data?.pages.flatMap((page) => page.items) || [];

  // Extract totalCount from the first page
  const totalCount = data?.pages?.[0]?.totalCount || 0;

  if (isError) {
    console.error(`[usePagination] Error encountered:`, error);
  }

  return { items, totalCount, fetchNextPage, hasNextPage, isLoading, isError, refetch };
};

export default usePagination;
