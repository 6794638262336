import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode"; // Ensure this is properly installed
import accountActions from "../store/actions/accounts/account.actions";

const useAccount = () => {
  const dispatch = useDispatch();
  const { token, isAuthenticated, isLoggedIn, userInfo } = useSelector((state) => state.accountReducer);

  const [isTokenValid, setIsTokenValid] = useState(true);

  // Decode and validate the JWT token
  const validateToken = useCallback(() => {
    if (!token) return false;

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp > currentTime; // Check if token is still valid
    } catch (err) {
      console.error("Invalid token:", err);
      return false;
    }
  }, [token]);

  // Fetch user info only if needed
  const fetchUserInfo = useCallback(async () => {
    if (isAuthenticated && validateToken()) {
      setIsTokenValid(true);

      // ✅ Check if userInfo is already stored before making API request
      if (!userInfo || Object.keys(userInfo).length === 0) {
        try {
          await dispatch(accountActions.getUserInfo(token));
        } catch (err) {
          console.error("Failed to fetch user info:", err);
          dispatch(accountActions.logout());
        }
      }
    } else {
      setIsTokenValid(false);
      dispatch(accountActions.logout());
    }
  }, [isAuthenticated, validateToken, userInfo, dispatch, token]);

  // Logout user
  const logout = useCallback(() => {
    dispatch(accountActions.logout());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserInfo();
    }
  }, [isAuthenticated, fetchUserInfo]);

  return {
    isLoggedIn,
    isTokenValid,
    userInfo,
    token,
    isAuthenticated,
    logout,
    validateToken,
  };
};

export default useAccount;
