import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import accountActions from "../../store/actions/accounts/account.actions";
import { defaultRegisterCredentials } from "../../models/accounts/registerCredentials.default";

export const ResetUserPasswordPageComponent = ({ accountActions }) => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmail(value);
  };

  const handleResetUserPassword = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      const response = await accountActions.resetUserPassword(email);
      console.log("🚀 ~ handleResetUserPassword ~ response:", response)
    } catch (error) {}
  };

  return (
    <div>
      <form onSubmit={handleResetUserPassword}>
        <input type="text" placeholder="enter email" name="email" value={email} onChange={handleInputChange} />
        <button onClick={handleResetUserPassword}>login</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetUserPasswordPageComponent);
