import React, { useState } from "react";
import "../../../styles/components/elements/inputElements/selectInputElementComponent.scss";

export const SelectInputElementComponent = ({ props }) => {
  const {
    title,
    label,
    name,
    value,
    options,
    onChange,
    required,
    clearBaseDesign,
    customStyleClass,
    disabled,
    searchable = false, // New prop to enable search
    placeholder = "חפש...",
  } = props;

  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filteredOptions = options?.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()) || (option.subOptions && option.subOptions.some((sub) => sub.label.toLowerCase().includes(searchQuery.toLowerCase()))));

  const getSelectElementOptions = () => {
    const elementOptions = [];
    elementOptions.push(
      <option key={-1} value="">
        בחר {label}
      </option>
    );
    filteredOptions?.forEach((option) => {
      if (option?.subOptions && option?.subOptions?.length > 0) {
        elementOptions.push(
          <optgroup key={option.value} label={option.label}>
            {option.subOptions?.map((subOption) => (
              <option key={subOption.value} value={subOption.value}>
                {subOption.label}
              </option>
            ))}
          </optgroup>
        );
      } else {
        elementOptions.push(
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        );
      }
    });

    return elementOptions;
  };

  return (
    <div className={`${clearBaseDesign ? "" : "select-element-container"} ${customStyleClass || ""}`}>
      <label htmlFor={name}>{title ? title : label}</label>
      {searchable && (
        <input
          type="text"
          className="search-input"
          placeholder={placeholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setIsDropdownOpen(true)}
          onBlur={() => setTimeout(() => setIsDropdownOpen(false), 150)} // Delay to allow item selection
        />
      )}
      <select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        size={isDropdownOpen ? Math.min(filteredOptions?.length + 1, 5) : 1} // Limit dropdown size
      >
        {getSelectElementOptions()}
      </select>
    </div>
  );
};

export default SelectInputElementComponent;
