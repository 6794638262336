import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import accountActions from "../../store/actions/accounts/account.actions";
import { defaultRegisterCredentials } from "../../models/accounts/registerCredentials.default";

export const GetUserInfoPageComponent = ({ accountActions }) => {
  const [token, setToken] = useState("");
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setToken(value);
  };

  const handleGetUserInfo = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      const response = await accountActions.getUserInfo(token);
      console.log("🚀 ~ handleGetUserInfo ~ response:", response)
    } catch (error) {}
  };

  return (
    <div>
      <form onSubmit={handleGetUserInfo}>
        <input type="text" placeholder="enter token" name="token" value={token} onChange={handleInputChange} />
        <button onClick={handleGetUserInfo}>get user info</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetUserInfoPageComponent);
