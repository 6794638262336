import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import "../../styles/pages/products/productGalleryPageComponent.scss";
import ProductListSortAndFilterComponent from "../../components/products/ProductGalleryPageComponent/ProductListSortAndFilterComponent";
import ProductListComponent from "../../components/products/ProductGalleryPageComponent/ProductListComponent";
export const ProductGalleryPageComponent = () => {
  const { categoryId } = useParams();
  const dispatch = useDispatch();

  return (
    <div className="productGalleryContaier">
      <div className="filterAndSortContainer">
        <ProductListSortAndFilterComponent />
      </div>
      <div className="listContainer">
        <ProductListComponent />
      </div>
    </div>
  );
};

export default ProductGalleryPageComponent;
