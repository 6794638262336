const mapProductToCartItem = (product) => {
  return {
    id: product.id,
    name: product.productName,
    price: product.price || 0, // Fallback price logic
    salePrice: product.salePrice || 0,
    quantity: 1, // Default quantity
    image: product.image ? product.image : product.images?.find((img) => img.id === product.primaryImageId)?.url || product.images[0]?.url, // Get primary image URL
    attributes:
      product.attributes?.map((attr) => ({
        name: attr.name,
        value: attr.attributeValues.map((val) => val.value).join(", "), // Join attribute values
      })) || [],
  };
};

const shoppingCartHelper = {
  mapProductToCartItem,
};
export default shoppingCartHelper;
