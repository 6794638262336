import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import accountActions from "../../store/actions/accounts/account.actions";
import { defaultChangeUserPassword } from "../../models/accounts/changeUserPassword.default";

export const ChangeUserPasswordPageComponent = ({ accountActions }) => {
  const [changeUserPasswordDetails, setChangeUserPasswordDetails] = useState(defaultChangeUserPassword);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setChangeUserPasswordDetails({ ...changeUserPasswordDetails, [name]: value });
  };

  const handleChangeUserPassword = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      const response = await accountActions.changeUserPassword(changeUserPasswordDetails);
    } catch (error) {}
  };

  return (
    <div>
      <form onSubmit={handleChangeUserPassword}>
        <input type="text" placeholder="enter email" name="email" value={changeUserPasswordDetails.email} onChange={handleInputChange} />
        <input type="oldPassword" placeholder="enter old Password" name="oldPassword" value={changeUserPasswordDetails.oldPassword} onChange={handleInputChange} />
        <input type="newPassword" placeholder="enter new Password" name="newPassword" value={changeUserPasswordDetails.newPassword} onChange={handleInputChange} />
        <button onClick={handleChangeUserPassword}>change password</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserPasswordPageComponent);
