import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useState } from "react";

import "../../../styles/components/elements/swiper/swiperThumbnailSliderGalleryComponent.scss";

export const SwiperThumbnailSliderGalleryComponent = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="SwiperThumbnailSliderGalleryContainer">
      {/* Main Image Swiper */}
      <Swiper modules={[Navigation, Thumbs]} navigation thumbs={{ swiper: thumbsSwiper }} className="mainSwiper">
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img.src} alt={img.alt} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Thumbnail Swiper */}
      <Swiper modules={[Thumbs]} onSwiper={setThumbsSwiper} slidesPerView={4} watchSlidesProgress className="thumbnailSwiper">
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img.src} alt={img.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperThumbnailSliderGalleryComponent;
