import React, { useEffect, useRef } from "react";
import "../../styles/components/elements/tableElementComponent.scss";

const TableElementComponent = ({ columns, data, actions }) => {
  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      const columnCount = actions ? columns.length + 1 : columns.length; // Account for action column
      tableRef.current.style.setProperty("--grid-columns", `repeat(${columnCount}, 1fr)`);
    }
  }, [columns, actions]);

  return (
    <div className="tableContainerWrapper">
      <div ref={tableRef} className="tableContainer">
        {/* Table Header */}
        <div className="tableHeader">
          {columns.map((col, index) => (
            <div key={index}>{col.label}</div>
          ))}
          {actions && <div>פעולות</div>} {/* Show actions column if provided */}
        </div>

        {/* Table Body */}
        <div className="tableBody">
          {data?.map((row, rowIndex) => (
            <div key={rowIndex} className="tableRow">
              {columns.map((col, colIndex) => (
                <div key={colIndex} className="rowData">
                  {col.render ? col.render(row[col.field], row) : row[col.field]}
                </div>
              ))}
              {actions && (
                <div className="actionsContainer">
                  {actions.map((action, actionIndex) => (
                    <button key={actionIndex} className={`actionButton ${action.className}`} onClick={() => action.onClick(row)}>
                      {action.icon && <i className={action.icon}></i>}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableElementComponent;
