import { attributeActionTypes } from "../../../actionTypes/products/attributes/attribute.actionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  attributes: [],
  attributeValues: [],
  selectedAttribute: null,
};

const attributeReducer = (state = initialState, action) => {
  switch (action.type) {
    case attributeActionTypes.START_ADD_ATTRIBUTE:
    case attributeActionTypes.START_UPDATE_ATTRIBUTE:
    case attributeActionTypes.START_DELETE_ATTRIBUTE:
    case attributeActionTypes.START_GET_ATTRIBUTE_BY_ID:
    case attributeActionTypes.START_GET_ATTRIBUTES:
    case attributeActionTypes.START_ADD_ATTRIBUTE_VALUE:
    case attributeActionTypes.START_UPDATE_ATTRIBUTE_VALUE:
    case attributeActionTypes.START_DELETE_ATTRIBUTE_VALUE:
    case attributeActionTypes.START_GET_ATTRIBUTE_VALUES:
    case attributeActionTypes.START_CONNECT_ATTRIBUTE_WITH_PRODUCT:
    case attributeActionTypes.START_DISCONNECT_ATTRIBUTE_WITH_PRODUCT:
      return setStateLoading(state);

    case attributeActionTypes.ADD_ATTRIBUTE_SUCCESS:
    case attributeActionTypes.UPDATE_ATTRIBUTE_SUCCESS:
    case attributeActionTypes.CONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS:
    case attributeActionTypes.DISCONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS:
      return setStateSuccess(state);

    case attributeActionTypes.DELETE_ATTRIBUTE_SUCCESS:
      return setStateSuccess(state);

    case attributeActionTypes.GET_ATTRIBUTE_BY_ID_SUCCESS:
      return setSelectedAttribute(state, action);

    case attributeActionTypes.GET_ATTRIBUTES_SUCCESS:
      return setAttributes(state, action);

    case attributeActionTypes.ADD_ATTRIBUTE_VALUE_SUCCESS:
    case attributeActionTypes.UPDATE_ATTRIBUTE_VALUE_SUCCESS:
      return setStateSuccess(state);

    case attributeActionTypes.DELETE_ATTRIBUTE_VALUE_SUCCESS:
      return setStateSuccess(state);

    case attributeActionTypes.GET_ATTRIBUTE_VALUES_SUCCESS:
      return setAttributeValues(state, action);

    case attributeActionTypes.ADD_ATTRIBUTE_FAILURE:
    case attributeActionTypes.UPDATE_ATTRIBUTE_FAILURE:
    case attributeActionTypes.DELETE_ATTRIBUTE_FAILURE:
    case attributeActionTypes.GET_ATTRIBUTE_BY_ID_FAILURE:
    case attributeActionTypes.GET_ATTRIBUTES_FAILURE:
    case attributeActionTypes.ADD_ATTRIBUTE_VALUE_FAILURE:
    case attributeActionTypes.UPDATE_ATTRIBUTE_VALUE_FAILURE:
    case attributeActionTypes.DELETE_ATTRIBUTE_VALUE_FAILURE:
    case attributeActionTypes.GET_ATTRIBUTE_VALUES_FAILURE:
    case attributeActionTypes.CONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE:
    case attributeActionTypes.DISCONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE:
      return setStateFailure(state, action);

    default:
      return state;
  }
};

const setStateLoading = (state) => ({
  ...state,
  stateInfo: {
    isLoading: true,
    isSuccess: false,
    isError: false,
    error: null,
  },
});

const setStateSuccess = (state) => ({
  ...state,
  stateInfo: {
    isLoading: false,
    isSuccess: true,
    isError: false,
    error: null,
  },
});

const setStateFailure = (state, action) => ({
  ...state,
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: true,
    error: action.error,
  },
});

const setAttributes = (state, action) => ({
  ...state,
  attributes: action.data,
  stateInfo: {
    isLoading: false,
    isSuccess: true,
    isError: false,
    error: null,
  },
});

const setSelectedAttribute = (state, action) => ({
  ...state,
  selectedAttribute: action.data,
  stateInfo: {
    isLoading: false,
    isSuccess: true,
    isError: false,
    error: null,
  },
});

const setAttributeValues = (state, action) => ({
  ...state,
  attributeValues: action.data,
  stateInfo: {
    isLoading: false,
    isSuccess: true,
    isError: false,
    error: null,
  },
});

export default attributeReducer;
