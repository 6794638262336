import React from "react";
import { Dialog } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AddOrUpdateCategoryDialogComponent from "./products/categories/AddOrUpdateCategoryDialogComponent";
import AddOrUpdateProductAttributeDialogComponent from "./products/attributes/AddOrUpdateProductAttributeDialogComponent";
import AddOrUpdateProductDialogComponent from "./products/AddOrUpdateProductDialogComponent";
import UpdateProductImagesDialogComponent from "./products/UpdateProductImagesDialogComponent";
import UpdateProductAttributesDialogComponent from "./products/UpdateProductAttributesDialogComponent";

// Dialog routes and actions as an object
const dialogsRoutes = {
  category: {
    add: AddOrUpdateCategoryDialogComponent,
    update: AddOrUpdateCategoryDialogComponent,
  },
  attribute: {
    add: AddOrUpdateProductAttributeDialogComponent,
    update: AddOrUpdateProductAttributeDialogComponent,
  },
  product: {
    add: AddOrUpdateProductDialogComponent,
    update: AddOrUpdateProductDialogComponent,
    gallery: UpdateProductImagesDialogComponent,
    attributes: UpdateProductAttributesDialogComponent,
  },
  //   websiteComfiguration: {
  //     manage: ManageWebsiteConfigurationsDialogComponent,
  //   },
};

export const DialogManagerComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dialogType = searchParams.get("d"); // Get the dialog type from the query parameter
  const actionType = searchParams.get("action") || "default"; // Get the action type or use "default"

  // Extract the current route and action
  const currentRoute = Object.keys(dialogsRoutes).find((route) => dialogType === route);
  const currentAction = dialogsRoutes[currentRoute]?.[actionType];

  // Check if the dialog should be open
  const isDialogOpen = !!currentRoute && !!currentAction;

  const getDialogContentComponent = () => {
    // Get the content component based on the route and action
    if (!currentRoute || !currentAction) return null;

    const DialogContentComponent = currentAction;
    return DialogContentComponent ? <DialogContentComponent /> : null;
  };

  const handleClose = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove the dialog-related query parameters
    newSearchParams.delete("d");
    newSearchParams.delete("action");
    newSearchParams.delete("itemId");
    // Update the URL with the modified search parameters
    setSearchParams(newSearchParams);
  };

  return (
    <Dialog open={isDialogOpen} onClose={handleClose} maxWidth={false} sx={{ zIndex: 1000 }}>
      <div className="dialogContainer">{getDialogContentComponent()}</div>
    </Dialog>
  );
};

export default DialogManagerComponent;
