import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AdminRoute = ({ children }) => {
  const userType = useSelector((state) => state.accountReducer.userInfo?.userType);

  if (userType !== "Administrator") {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AdminRoute;
