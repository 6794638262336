import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import productActions from "../../../store/actions/products/product.actions";
import AlertService from "../../../services/alerts/alert.service";
import TableElementComponent from "../../elements/TableElementComponent";
import validationHelper from "../../../helpers/validation.helper";
import attributeActions from "../../../store/actions/products/attributes/attribute.actions";
import SelectInputElementComponent from "../../elements/inputElements/SelectInputElementComponent";

export const UpdateProductAttributesDialogComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const attributes = useSelector((state) => state.attributeReducer.attributes?.items || []);

  const [productAttributes, setProductAttributes] = useState([]);
  const [selectedAttributeId, setSelectedAttributeId] = useState(null);
  const [selectedAttributeValueId, SetselectedAttributeValueId] = useState(null);
  const [product, setProduct] = useState(null);

  const productId = searchParams.get("id");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "selectedAttributeId") setSelectedAttributeId(value);
    else if (name === "selectedAttributeValueId") SetselectedAttributeValueId(value);
  };

  useEffect(() => {
    fetchProduct();
    fetchAttributes();
  }, [productId]);

  const fetchProduct = async () => {
    try {
      const response = await dispatch(productActions.getProductById(productId));
      setProduct(response.payload);
      setProductAttributes(response.payload?.attributes || []);
    } catch (error) {
      AlertService.error("שגיאה בטעינת הפריט", "לא ניתן לטעון את מאפייני הפריט.");
    }
  };

  const fetchAttributes = async () => {
    try {
      const queryParams = { getAll: true };
      const response = await dispatch(attributeActions.getAttributes(queryParams));
      setProductAttributes(response.payload?.attributes || []);
    } catch (error) {
      AlertService.error("שגיאה בטעינת מאפיינים", "לא ניתן לטעון את המאפיינים, אנא נסה שנית מאוחר יותר.");
    }
  };

  const handleDeleteAttributeValue = async (productId, attributeId, attributeValueId) => {
    try {
      const response = await dispatch(attributeActions.disconnectAttributeWithProduct(productId, attributeId, attributeValueId));
      if (!validationHelper.validateHttpResponse(response, false)) {
        AlertService.error("מחיקת ערך נכשלה. נסה שוב.");
        return;
      }
      AlertService.success("ערך נמחק בהצלחה");
      fetchProduct();
    } catch (error) {
      AlertService.error("שגיאה במחיקת ערך", "אנא נסה שוב מאוחר יותר");
    }
  };

  const handleConnectAttribute = async () => {
    if (!selectedAttributeId || !selectedAttributeValueId) {
      AlertService.error("אנא בחר מאפיין וערך מאפיין.");
      return;
    }

    try {
      const response = await dispatch(attributeActions.connectAttributeWithProduct(productId, selectedAttributeId, selectedAttributeValueId));
      if (!validationHelper.validateHttpResponse(response, false)) {
        AlertService.error("חיבור מאפיין למוצר נכשל. נסה שוב.");
        return;
      }
      AlertService.success("המאפיין נוסף בהצלחה");
      fetchProduct();
    } catch (error) {
      AlertService.error("שגיאה בהוספת מאפיין", "אנא נסה שוב מאוחר יותר");
    }
  };

  // ********** Get Elements ********
  const getAttributeSelectElement = () => {
    if (!attributes || !Array.isArray(attributes)) return null; // Prevents crashing

    const attributesOptions = attributes.map((attribute) => ({
      value: attribute.id,
      label: attribute.name,
    }));

    const inputProps = {
      name: "selectedAttributeId",
      value: selectedAttributeId,
      options: attributesOptions,
      onChange: handleInputChange,
      searchable: false,
      label: "מאפיין פריט",
    };

    return <SelectInputElementComponent props={inputProps} />;
  };

  const getAttributeValuesSelectElement = () => {
    const attribute = attributes.find((attribute) => attribute.id === selectedAttributeId);
    const attributeValuesOptions = attribute?.attributeValues.map((attributeValue) => ({ value: attributeValue.id, label: attributeValue.value }));

    const inputProps = {
      name: "selectedAttributeValueId",
      value: selectedAttributeValueId,
      options: attributeValuesOptions,
      onChange: handleInputChange,
      searchable: false,
      label: "ערכי מאפיין",
    };

    return <SelectInputElementComponent props={inputProps} />;
  };

  const getProductAttributeValuesTable = () => {
    const columns = [
      { label: "מאפיין", field: "attributeName" }, // Attribute Name
      { label: "ערך", field: "attributeValue" }, // Attribute Value
    ];

    const actions = [
      {
        label: "מחק",
        icon: "fa-solid fa-trash",
        className: "redButton",
        onClick: (row) => handleDeleteAttributeValue(productId, row.attributeId, row.attributeValueId),
      },
    ];

    // Transform attributes into a flat list for table rendering and remove duplicates
    const formattedAttributes = productAttributes.flatMap((attribute) =>
      attribute.attributeValues.map((value) => ({
        productAttributeId: value.id, // The specific attribute-value ID
        attributeId: attribute.id, // Attribute ID
        attributeValueId: value.id, // Attribute Value ID
        attributeName: attribute.name, // Attribute Name
        attributeValue: value.value, // Value of the attribute
      }))
    );

    // Use a Set to filter out duplicates based on `attributeName` and `attributeValue`
    const uniqueAttributes = Array.from(new Map(formattedAttributes.map((item) => [`${item.attributeName}-${item.attributeValue}`, item])).values());

    return (
      <div className="attributeValuesContainer">
        <h3>מאפייני פריט</h3>
        <TableElementComponent columns={columns} data={uniqueAttributes} actions={actions} />
      </div>
    );
  };

  return (
    <div>
      <h2>{product?.productName}</h2>
      {getAttributeSelectElement()}
      {selectedAttributeId && getAttributeValuesSelectElement()}
      <button className="primaryButton" onClick={handleConnectAttribute}>
        שייך מאפיין
      </button>
      {getProductAttributeValuesTable()}
    </div>
  );
};

export default UpdateProductAttributesDialogComponent;
