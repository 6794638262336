import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import usePagination from "../../../hooks/usePagination";
import { useDispatch } from "react-redux";
import productActions from "../../../store/actions/products/product.actions";
import ProductCardComponent from "./ProductCardComponent";
import "../../../styles/components/products/productListPageComponent.scss";

export const ProductListComponent = () => {
  const { categoryId } = useParams();
  const [searchParams] = useSearchParams(); // Get filters from the URL
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({}); // Store extracted filters separately

  // Extract filters from searchParams
  useEffect(() => {
    const extractedFilters = {};
    searchParams.forEach((value, key) => {
      extractedFilters[key] = value.includes(",") ? value.split(",") : [value]; // Ensure values are always arrays
    });
    setFilters(extractedFilters);
  }, [searchParams]);

  const { items, fetchNextPage, hasNextPage, refetch } = usePagination({
    queryKey: ["products", categoryId, JSON.stringify(filters)], // Trigger refetch when filters change
    fetchFunction: async (pageParam) => {
      const params = {
        pageNumber: pageParam,
        pageSize: 10,
        categoryId: categoryId || null,
        filters: filters, // Use extracted filters
      };
      return await dispatch(productActions.getProducts(params));
    },
  });

  useEffect(() => {
    if (categoryId !== undefined || Object.keys(filters).length > 0) {
      refetch();
    }
  }, [categoryId, filters]);

  useEffect(() => {
    if (items.length !== products.length) {
      setProducts(items);
    }
  }, [items]);

  return (
    <div className="productListContainer">
      {products.map((product) => (
        <ProductCardComponent key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductListComponent;
