import { appHttpClient } from "../../../installations/http/axiosInterfaces";

export const addCategory = (data) => {
  return appHttpClient.post(`/Category/Add`, data);
};

export const updateCategory = (data) => {
  return appHttpClient.patch(`/Category/Update`, data);
};

export const updateCategoryImage = (categoryId, data) => {
  return appHttpClient.patch(`/Category/Update/Image/${categoryId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data", // Inform the server that this is form data
    },
  });
};

export const deleteCategory = (categoryId) => {
  return appHttpClient.delete(`/Category/Delete/${categoryId}`);
};

export const getCategoryById = (categoryId) => {
  return appHttpClient.get(`/Category/${categoryId}`);
};

export const getCategories = (queryParams) => {
  return appHttpClient.get(`/Category/GetCategories`, { params: queryParams });
};

const categoryService = {
  addCategory,
  updateCategory,
  updateCategoryImage,
  deleteCategory,
  getCategoryById,
  getCategories,
};

export default categoryService;
