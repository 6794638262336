import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import attributeActions from "../../../store/actions/products/attributes/attribute.actions";
import CheckboxElementComponent from "../../elements/inputElements/CheckboxElementComponent";
import useMobileScreen from "../../../hooks/useMobileScreen";
import "../../../styles/components/products/productListSortAndFilterComponent.scss";

export const ProductListSortAndFilterComponent = () => {
  const { isMobileScreen, currentBreakpoint } = useMobileScreen();
  const attributes = useSelector((state) => state.attributeReducer.attributes.items || []);
  const totalProductsToShow = useSelector((state) => state.productReducer.totalProducts);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchParams, setSearchParams] = useSearchParams(); // Search params hook

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  useEffect(() => {
    fetchAttributes();
    loadFiltersFromParams(); // Load filters from URL when component mounts
  }, []);

  // Fetch attributes for filtering in the dropdown menu
  const fetchAttributes = async () => {
    const params = { getAll: true };
    await dispatch(attributeActions.getAttributes(params));
  };

  // Load filters from URL on first render
  const loadFiltersFromParams = () => {
    const newFilters = {};
    searchParams.forEach((value, key) => {
      newFilters[key] = value.split(","); // Convert comma-separated values into an array
    });
    setSelectedFilters(newFilters);
  };

  const toggleFilterMenu = () => {
    setIsFilterMenuOpen((prevState) => {
      const newState = !prevState;

      // Toggle body scroll lock
      if (newState) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }

      return newState;
    });
  };

  // Handle checkbox selection
  const handleCheckboxChange = (attributeId, valueId) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (!updatedFilters[attributeId]) {
        updatedFilters[attributeId] = [];
      }

      if (updatedFilters[attributeId].includes(valueId)) {
        updatedFilters[attributeId] = updatedFilters[attributeId].filter((id) => id !== valueId);
      } else {
        updatedFilters[attributeId] = [...updatedFilters[attributeId], valueId];
      }

      // If no values are left for an attribute, remove it from search params
      if (updatedFilters[attributeId].length === 0) {
        delete updatedFilters[attributeId];
      }

      // Update the URL search params
      const newSearchParams = new URLSearchParams();
      Object.entries(updatedFilters).forEach(([key, values]) => {
        newSearchParams.set(key, values.join(",")); // Store as comma-separated values
      });
      setSearchParams(newSearchParams);

      return updatedFilters;
    });
  };

  if (!attributes) return null;

  const hideSmallScreenElements = () => {
    if (isMobileScreen === false && currentBreakpoint !== "sm") return true;
    return false;
  };

  const getFilterContainerElement = () => {
    if ((isMobileScreen || currentBreakpoint === "sm") && isFilterMenuOpen === false) return null;
    return (
      <div className="productListSortAndFilterContainer">
        <h3>אפשרויות סינון</h3>
        <div className="filterOptions">
          {attributes.map((attribute) => (
            <div key={attribute.id} className="filterCategory">
              <h4>{attribute.name}</h4>
              <div className="filterCheckboxes">
                {attribute.attributeValues.map((value) => (
                  <CheckboxElementComponent
                    key={value.id}
                    props={{
                      name: value.id,
                      label: value.value,
                      checked: selectedFilters[attribute.id]?.includes(value.id) || false,
                      onChange: () => handleCheckboxChange(attribute.id, value.id),
                    }}
                  />
                ))}
              </div>
            </div>
          ))}

          {!hideSmallScreenElements() && (
            <button className="primaryButton" onClick={toggleFilterMenu}>
              סגור
            </button>
          )}
        </div>
      </div>
    );
  };

  const getSmallScreenFilterButton = () => {
    if (hideSmallScreenElements()) return null;
    return (
      <div className="smallScreenFilterTitleContainer">
        <label className="toggleButton" onClick={toggleFilterMenu}>
          <span>
            <i class="fa-solid fa-sliders"></i>
          </span>
          <span>סינון</span>
        </label>
        <label>{totalProductsToShow} פריטים</label>
      </div>
    );
  };

  return (
    <div className="productListSortAndFilter">
      {getSmallScreenFilterButton()}
      {getFilterContainerElement()}
    </div>
  );
};

export default ProductListSortAndFilterComponent;
