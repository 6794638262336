import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";

import accountActions from "../../store/actions/accounts/account.actions";
import { defaultRegisterCredentials } from "../../models/accounts/registerCredentials.default";

export const RegisterPageComponent = ({accountActions}) => {
  const [credentails, setCredentials] = useState(defaultRegisterCredentials);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentails, [name]: value });
  };

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      const response = await accountActions.register(credentails);
      console.log("🚀 ~ handleLogin ~ response:", response)
    } catch (error) {}
  };

  return (
    <div>
      <form onSubmit={handleLogin}>
        <input type="firstName" placeholder="enter firstName" name="firstName" value={credentails.firstName} onChange={handleInputChange} />
        <input type="lastName" placeholder="enter lastName" name="lastName" value={credentails.lastName} onChange={handleInputChange} />
        <input type="text" placeholder="enter email" name="email" value={credentails.email} onChange={handleInputChange} />
        <input type="password" placeholder="enter password" name="password" value={credentails.password} onChange={handleInputChange} />
        <button onClick={handleLogin}>register</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userReducer: state.userReducer,
  websiteReducer: state.websiteReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPageComponent);
