import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import categoryActions from "../../../store/actions/products/categories/category.actions";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import "../../../styles/layouts/header/_headerCategoriesNavLinksComponent.scss";
import useMobileScreen from "../../../hooks/useMobileScreen";
import useAccount from "../../../hooks/account.hook";

export const HeaderCategoriesNavLinksComponent = ({ props, categoryActions }) => {
  const { toggleOpenNavMenu } = props;
  const { isAuthenticated, userInfo } = useAccount();

  const [categories, setCategories] = useState([]);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [isSubMenuHovered, setIsSubMenuHovered] = useState(false);
  const {isMobileScreen} = useMobileScreen();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const queryParams = { getAll: true };
    try {
      const response = await categoryActions.getCategories(queryParams);
      setCategories(response.payload.items);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleMouseEnterParent = (categoryId) => {
    if (isMobileScreen) return;
    setActiveCategoryId(categoryId);
  };

  const handleMouseLeaveParent = () => {
    if (!isSubMenuHovered) {
      setActiveCategoryId(null);
    }
  };

  const handleMouseEnterSubMenu = () => {
    if (isMobileScreen) return;
    setIsSubMenuHovered(true);
  };

  const handleMouseLeaveSubMenu = () => {
    if (isMobileScreen) return;
    setIsSubMenuHovered(false);
    setActiveCategoryId(null);
  };

  const handleCategoryLinkClick = () => {
    if (isMobileScreen === false) return;
    toggleOpenNavMenu();
  };

  const renderSubCategories = (subCategories) => {
    return subCategories.map((subcategory) => (
      <Link key={subcategory.id} to={`/categories/${subcategory.id}`} className="subcategoryLink">
        <div
          onClick={handleCategoryLinkClick}
          className="subcategoryImage"
          style={{
            backgroundImage: `url(${subcategory.imageURL || ""})`,
            backgroundSize: "cover",
          }}
        >
          <span>{subcategory.categoryName}</span>
        </div>
      </Link>
    ));
  };

  const getDashboardLinkElement = () => {
    if (isAuthenticated && userInfo?.id && userInfo.userType === "Administrator")
      return (
        <Link to={`/admin/dashboard/${userInfo.id}`} className="parentCategoryLink" onClick={handleCategoryLinkClick}>
          איזור אישי
        </Link>
      );
  };

  const renderCategories = (category) => {
    if (!isMobileScreen && category.parentCategoryID !== null) return null; // Render only parent categories

    return (
      <div key={category.id} className="parentCategoryContainer" onMouseEnter={() => handleMouseEnterParent(category.id)} onClick={handleCategoryLinkClick}>
        <Link to={`/categories/${category.id}`} className="parentCategoryLink">
          {category.categoryName}
        </Link>
        {activeCategoryId === category.id && category.subCategories.length > 0 && (
          <div className="subCategoriesContainer" onMouseEnter={handleMouseEnterSubMenu} onMouseLeave={handleMouseLeaveSubMenu}>
            {renderSubCategories(category.subCategories)}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="categoriesLinksContainer">
      {categories.map((category) => renderCategories(category))}
      {getDashboardLinkElement()}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(HeaderCategoriesNavLinksComponent);
