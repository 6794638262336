import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import categoryActions from "../../../store/actions/products/categories/category.actions";
import { useEffect, useState } from "react";
import HeaderCategoriesNavLinksComponent from "./HeaderCategoriesNavLinksComponent";
import HeaderLogoComponent from "./HeaderLogoComponent";

import "../../../styles/layouts/header/_header.scss";
import useMobileScreen from "../../../hooks/useMobileScreen";
import useAccount from "../../../hooks/account.hook";

export const HeaderComponent = ({}) => {
  const {isMobileScreen} = useMobileScreen();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, logout, userInfo } = useAccount();

  const toggleOpenNavMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleHamburgerMenuClicked = () => {
    toggleOpenNavMenu(!isMenuOpen);
  };

  const showHeaderContentContainer = () => {
    if (isMobileScreen == false) return true;
    return isMenuOpen;
  };

  const getHamburgerMenuElement = () => {
    const element = (
      <div className="hamburgerMenu" onClick={handleHamburgerMenuClicked}>
        <i className="fa-solid fa-bars"></i>
      </div>
    );
    return isMobileScreen ? element : null;
  };

  const getHeaderContentContainerElement = () => {
    if (!showHeaderContentContainer()) return null;
    const headerContentProps = { toggleOpenNavMenu };

    return (
      <div className="headerContentContainer">
        <HeaderCategoriesNavLinksComponent props={headerContentProps} />
      </div>
    );
  };

  const getLoginLinkElement = () => {
    if (isAuthenticated) {
      return (
        <div className="logout" onClick={logout}>
          <i className="fa-solid fa-right-from-bracket"></i>
        </div>
      );
    } else {
      return (
        <Link to="/login">
          <i className="fa-solid fa-user"></i>
        </Link>
      );
    }
  };

  const getShoppingCartLinkElement = () => {
    return (
      <Link to="/shoppingCart">
        <i class="fa-solid fa-cart-shopping"></i>
      </Link>
    );
  };

  return (
    <header className="headerContainer">
      {getHamburgerMenuElement()}
      <HeaderLogoComponent />
      {getHeaderContentContainerElement()}
      <div className="generalLinksContainer">
        {/* {getLoginLinkElement()} */}
        {getShoppingCartLinkElement()}
      </div>
      {isMenuOpen && <div className="fullScreen" onClick={toggleOpenNavMenu}></div>}
    </header>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(HeaderComponent);
