import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart, clearCart, updateQuantity } from "../store/slices/shoppingCart/shoppingCart.slice";

const useShoppingCart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.shoppingCart.items);

  const addItem = (item) => dispatch(addToCart(item));
  const removeItem = (id) => dispatch(removeFromCart(id));
  const updateItemQuantity = (id, quantity) => dispatch(updateQuantity({ id, quantity }));
  const clearCartItems = () => dispatch(clearCart());

  return { cartItems, addItem, removeItem, updateItemQuantity, clearCartItems };
};

export default useShoppingCart;
