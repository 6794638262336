export const textInputModel = {
  name: "", // The name of the input field
  label: "", // The label to display for the input
  value: "", // The current value of the input field
  type: "text", // The type of the input field
  onChange: () => {}, // A function to handle value changes
  required: false, // Whether the input is required
  clearDesgin: false, // Whether to apply a clear design
  customDesignClass: "", // Additional custom classes for styling
  placeholder: "", // Placeholder text for the input
  error: null,
  icon: null,
};

export default textInputModel;
