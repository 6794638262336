import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import productActions from "../../../store/actions/products/product.actions";
import AlertService from "../../../services/alerts/alert.service";
import LoaderComponent from "../../elements/LoaderComponent";
import TableElementComponent from "../../elements/TableElementComponent";
import { useSearchParams } from "react-router-dom";
import { fileTypesEnum } from "../../../enums/fileTypes.enum";
import FileInputElementComponent from "../../elements/inputElements/FileInputElementComponent";

const UpdateProductImagesDialogComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [currentPrimaryImageId, setCurrentPrimaryImageId] = useState(null);
  const [images, setImages] = useState([]);
  const [currentValue, setCurrentValue] = useState("");

  const actionType = searchParams.get("action");
  const productId = searchParams.get("id");

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  const fetchProduct = async () => {
    try {
      const response = await dispatch(productActions.getProductById(productId));
      setCurrentPrimaryImageId(response.payload?.primaryImageId || null);
      setImages(response.payload?.images || []);
    } catch (error) {
      AlertService.error("שגיאה בטעינת תמונות", "לא ניתן לטעון את תמונות המוצר.");
    }
  };

  const handleFileChange = (file, fileDetails) => {
    setCurrentValue({ ...currentValue, file, Url: fileDetails.url });
  };

  const handleUploadImage = async () => {
    if (!currentValue?.file) {
      AlertService.warning("בחירת קובץ", "אנא בחר תמונה להעלאה.");
      return;
    }
    try {
      await dispatch(productActions.addProductImage(productId, currentValue.file, false));
      AlertService.success("העלאה הצליחה", "התמונה נוספה בהצלחה.");
      fetchProduct();
    } catch (error) {
      AlertService.error("שגיאה בהעלאה", "אירעה שגיאה בהעלאת התמונה.");
    }
  };

  const handleSetPrimary = async (imageId) => {
    try {
      await dispatch(productActions.setImageAsPrimary(productId, imageId));
      AlertService.success("עודכן בהצלחה", "התמונה הראשית עודכנה.");
      fetchProduct();
    } catch (error) {
      AlertService.error("שגיאה", "לא ניתן לעדכן את התמונה הראשית.");
    }
  };

  const handleDeleteImage = async (imageId) => {
    const isConfirmed = await AlertService.confirm("מחיקת תמונה", "האם אתה בטוח שברצונך למחוק את התמונה?", "כן, מחק", "ביטול");

    if (!isConfirmed) return;

    try {
      await dispatch(productActions.deleteProductImage(productId, imageId));
      AlertService.success("תמונה נמחקה", "התמונה נמחקה בהצלחה.");
      fetchProduct();
    } catch (error) {
      AlertService.error("שגיאה במחיקה", "אירעה שגיאה בעת מחיקת התמונה.");
    }
  };

  const getImagesTableElement = () => {
    const columns = [
      {
        label: "תמונה",
        field: "url",
        render: (value) => (
          <div className="imageColumnContainer">
            <img src={value || "https://via.placeholder.com/50"} alt="תמונה" />
          </div>
        ),
      },
      {
        label: "תמונה ראשית",
        field: "id",
        render: (value) => <span>{value === currentPrimaryImageId ? "כן" : "לא"}</span>,
      },
    ];
    const actions = [
      {
        label: "סמן כראשית",
        icon: "fa-solid fa-font-awesome",
        className: "greenButton",
        onClick: (image) => handleSetPrimary(image.id),
      },
      {
        label: "מחק תמונה",
        icon: "fa-solid fa-trash",
        className: "redButton",
        onClick: (image) => handleDeleteImage(image.id),
      },
    ];

    if (!images || images.length === 0) return null;
    return <TableElementComponent columns={columns} data={images} actions={actions} />;
  };

  const getNewImageInputElement = () => {
    const inputProps = {
      label: "תמונת פריט ראשית",
      name: "newImage",
      value: currentValue.Url,
      fileType: fileTypesEnum.image,
      onChange: handleFileChange,
    };
    return <FileInputElementComponent props={inputProps} />;
  };

  return (
    <div className="updateProductImagesDialog">
      <h2>ניהול תמונות מוצר</h2>
      <div>
        {getNewImageInputElement()}
        <button className="primaryButton" onClick={handleUploadImage}>
          העלה תמונה
        </button>
      </div>
      {getImagesTableElement()}
    </div>
  );
};

export default UpdateProductImagesDialogComponent;
