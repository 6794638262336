import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { attributeModel } from "../../../../models/products/attributes/attribute.model";
import attributeActions from "../../../../store/actions/products/attributes/attribute.actions";
import validationHelper from "../../../../helpers/validation.helper";
import AlertService from "../../../../services/alerts/alert.service";
import textInputModel from "../../../../models/elements/inputElements/textInput.model";
import TextInputElementComponent from "../../../elements/inputElements/TextInputElementComponent";
import TextAreaInputElementComponent from "../../../elements/inputElements/TextAreaInputElementComponent";
import TableElementComponent from "../../../elements/TableElementComponent";

const AddOrUpdateProductAttributeDialogComponent = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const attributes = useSelector((state) => state.attributeReducer.attributes.items);

  const [attributeDetails, setAttributeDetails] = useState(attributeModel);
  const [attributeValue, setAttributeValue] = useState("");
  const [errors, setErrors] = useState({});

  const actionType = searchParams.get("action");
  const attributeId = searchParams.get("id");

  useEffect(() => {
    if (actionType === "update" && attributeId) {
      fetchAttributeDetails();
    }
  }, [actionType, attributeId]);

  const fetchAttributeDetails = async () => {
    try {
      const response = await dispatch(attributeActions.getAttributeById(attributeId));

      if (!validationHelper.validateHttpResponse(response, true)) {
        AlertService.error("שגיאה בטעינת המאפיין", "אנא נסה שוב מאוחר יותר");
        return;
      }

      setAttributeDetails(response.payload);
    } catch (error) {
      AlertService.error("שגיאה בטעינת המאפיין", "אנא נסה שוב מאוחר יותר");
      console.error("Error fetching attribute details:", error);
    }
  };

  // ********** Input Change Event ********
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAttributeDetails({ ...attributeDetails, [name]: value });
  };

  // ********** Submit Event ********
  const validateForm = () => {
    const newErrors = {};

    if (!attributeDetails.name || attributeDetails.name.trim() === "") {
      newErrors.name = "שם המאפיין הוא שדה חובה";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    try {
      if (actionType === "update") await handleUpdateAttribute();
      else await handleAddAttribute();
    } catch (error) {
      setErrors({
        general: actionType === "update" ? "שגיאה בעדכון המאפיין. נסה שוב מאוחר יותר." : "שגיאה בשרת. נסה שוב מאוחר יותר.",
      });
    }
  };

  const handleClearSearchParams = () => {
    setSearchParams(new URLSearchParams());
  };

  const handleAddAttribute = async () => {
    const response = await dispatch(attributeActions.addAttribute(attributeDetails));
    if (!validationHelper.validateHttpResponse(response, false)) {
      setErrors({ general: "הוספת מאפיין נכשלה. נסה שוב." });
      return;
    }
    AlertService.success("מאפיין נוסף בהצלחה");
    handleClearSearchParams();
  };

  const handleUpdateAttribute = async () => {
    const response = await dispatch(attributeActions.updateAttribute(attributeDetails));
    if (!validationHelper.validateHttpResponse(response, false)) {
      setErrors({ general: "עדכון המאפיין נכשל" });
      return;
    }
    AlertService.success("המאפיין עודכן בהצלחה");
    handleClearSearchParams();
  };

  // ********** Attribute Values Management ********
  const handleAddAttributeValue = async () => {
    if (!attributeValue) {
      const newErrors = {};
      newErrors.attributeValue = "ערך מאפיין הוא שדה חובה";
      setErrors(newErrors);
      return;
    }

    try {
      const response = await dispatch(attributeActions.addAttributeValue({ attributeID: attributeDetails.id, value: attributeValue }));
      if (!validationHelper.validateHttpResponse(response, false)) {
        AlertService.error("הוספת ערך נכשלה. נסה שוב.");
        return;
      }
      AlertService.success("ערך נוסף בהצלחה");
      setAttributeValue("");
      fetchAttributeDetails(); // Refresh values list
    } catch (error) {
      AlertService.error("שגיאה בהוספת ערך", "אנא נסה שוב מאוחר יותר");
    }
  };

  const handleDeleteAttributeValue = async (valueId) => {
    try {
      const response = await dispatch(attributeActions.deleteAttributeValue(valueId));
      if (!validationHelper.validateHttpResponse(response, false)) {
        AlertService.error("מחיקת ערך נכשלה. נסה שוב.");
        return;
      }
      AlertService.success("ערך נמחק בהצלחה");
      fetchAttributeDetails();
    } catch (error) {
      AlertService.error("שגיאה במחיקת ערך", "אנא נסה שוב מאוחר יותר");
    }
  };

  // ********** Get Elements ********
  const getAttributeNameInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "name",
      error: errors.name,
      value: attributeDetails.name,
      onChange: handleInputChange,
      label: "שם המאפיין",
    };
    return <TextInputElementComponent props={inputProps} />;
  };

  const getAttributeDescriptionInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "description",
      error: errors.description,
      value: attributeDetails.description,
      onChange: handleInputChange,
      label: "תיאור המאפיין",
    };
    return <TextAreaInputElementComponent props={inputProps} />;
  };

  const getAttributeValuesContainerElement = () => {
    if (actionType !== "update") return null;

    return (
      <div className="attributeValuesContainer">
        {getAttributeValueInputElement()}
        <button type="submit" className="primaryButton" onClick={handleAddAttributeValue}>
          הוסף ערך חדש
        </button>
        {getAttributeValuesTable()}
      </div>
    );
  };
  const getAttributeValueInputElement = () => {
    const inputProps = {
      ...textInputModel,
      name: "attributeValue",
      error: errors.attributeValue,
      value: attributeValue,
      onChange: (event) => {
        setAttributeValue(event.target.value);
      },
      label: "ערך מאפיין",
    };
    return <TextInputElementComponent props={inputProps} />;
  };

  const getAttributeValuesTable = () => {
    const columns = [{ label: "ערך", field: "value" }];
    const actions = [
      {
        label: "מחק",
        icon: "fa-solid fa-trash",
        className: "redButton",
        onClick: (value) => handleDeleteAttributeValue(value.id),
      },
    ];

    return (
      <div className="attributeValuesContainer">
        <h3>ערכים משויכים</h3>
        <TableElementComponent columns={columns} data={attributeDetails.attributeValues} actions={actions} />
      </div>
    );
  };

  return (
    <div className="addAttributeDialogContainer">
      <h1>{actionType === "update" ? "עדכון מאפיין" : "הוסף מאפיין"}</h1>
      <form onSubmit={handleSubmit}>
        {getAttributeNameInputElement()}
        {getAttributeDescriptionInputElement()}

        <button type="submit" className="primaryButton">
          {actionType === "update" ? "עדכון" : "הוסף"}
        </button>
        {errors.general && <span className="error">{errors.general}</span>}
      </form>

      {getAttributeValuesContainerElement()}
    </div>
  );
};

export default AddOrUpdateProductAttributeDialogComponent;
