import { productActionTypes } from "../../actionTypes/product.actionTypes";

const initialState = {
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  products: [],
  productDetails: null,
  totalProducts: 0,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case productActionTypes.START_ADD_PRODUCT:
    case productActionTypes.START_UPDATE_PRODUCT:
    case productActionTypes.START_DELETE_PRODUCT:
    case productActionTypes.START_GET_PRODUCT:
    case productActionTypes.START_GET_PRODUCTS:
    case productActionTypes.START_ADD_PRODUCT_IMAGE:
    case productActionTypes.START_DELETE_PRODUCT_IMAGE:
    case productActionTypes.START_SET_IMAGE_AS_PRIMARY:
      return setStateLoading(state);

    case productActionTypes.ADD_PRODUCT_SUCCESS:
    case productActionTypes.UPDATE_PRODUCT_SUCCESS:
    case productActionTypes.DELETE_PRODUCT_SUCCESS:
    case productActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS:
    case productActionTypes.SET_IMAGE_AS_PRIMARY_SUCCESS:
      return setStateSuccess(state);

    case productActionTypes.GET_PRODUCT_SUCCESS:
      return setProductDetails(state, action);

    case productActionTypes.GET_PRODUCTS_SUCCESS:
      return setProducts(state, action);

    case productActionTypes.ADD_PRODUCT_FAILURE:
    case productActionTypes.UPDATE_PRODUCT_FAILURE:
    case productActionTypes.DELETE_PRODUCT_FAILURE:
    case productActionTypes.GET_PRODUCT_FAILURE:
    case productActionTypes.GET_PRODUCTS_FAILURE:
    case productActionTypes.ADD_PRODUCT_IMAGE_FAILURE:
    case productActionTypes.DELETE_PRODUCT_IMAGE_FAILURE:
    case productActionTypes.SET_IMAGE_AS_PRIMARY_FAILURE:
      return setStateFailure(state, action);

    default:
      return state;
  }
};

const setStateLoading = (state) => ({
  ...state,
  stateInfo: {
    isLoading: true,
    isSuccess: false,
    isError: false,
    error: null,
  },
});

const setStateSuccess = (state) => ({
  ...state,
  stateInfo: {
    isLoading: false,
    isSuccess: true,
    isError: false,
    error: null,
  },
});

const setStateFailure = (state, action) => ({
  ...state,
  stateInfo: {
    isLoading: false,
    isSuccess: false,
    isError: true,
    error: action.error,
  },
});

const setProducts = (state, action) => ({
  ...state,
  stateInfo: {
    isLoading: false,
    isSuccess: true,
    isError: false,
    error: null,
  },
  products: action.data.items,
  totalProducts: action.data.totalItems,
});

const setProductDetails = (state, action) => ({
  ...state,
  stateInfo: {
    isLoading: false,
    isSuccess: true,
    isError: false,
    error: null,
  },
  productDetails: action.data,
});

export default productReducer;
