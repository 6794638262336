import { useState } from "react";
import "../../../styles/components/elements/inputElements/checkboxElementComponent.scss";

export const CheckboxElementComponent = ({ props }) => {
  const {
    name,
    label,
    checked,
    onChange,
    required,
    clearDesign,
    customDesignClass = "",
    error,
    style = {}, // Custom inline styles
    customCheckboxClass = "", // Additional custom class
  } = props;

  return (
    <div
      className={`${clearDesign ? "" : "checkboxElementContainer"} ${customDesignClass} defaultCheckbox`}
      style={style} // Apply custom styles
    >
      <label className="checkboxWrapper">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          required={required}
          className={`defaultCheckboxStyle ${customCheckboxClass}`} // Custom class support
        />
        <span className="customCheckbox"></span>
        {label && <span className="checkboxLabel">{label}</span>}
      </label>
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default CheckboxElementComponent;
