import { appHttpClient } from "../../installations/http/axiosInterfaces";

export const login = (data) => {
  return appHttpClient.post(`/Account/Login`, data);
};

export const register = (data) => {
  return appHttpClient.post(`/Account/Register`, data);
};

export const resetUserPassword = (data) => {
  return appHttpClient.post(`/Account/ResetPassword/${data}`);
};

export const changeUserPassword = (data) => {
  return appHttpClient.patch(`/Account/ChangePassword`, data);
};

export const getUserInfo = (data) => {
  return appHttpClient.get(`/Account/UserInfo/${data}`);
};

const accountService = {
  login,
  register,
  resetUserPassword,
  changeUserPassword,
  getUserInfo,
};

export default accountService;
