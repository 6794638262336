export const attributeActionTypes = {
    START_ADD_ATTRIBUTE: "START_ADD_ATTRIBUTE",
    ADD_ATTRIBUTE_SUCCESS: "ADD_ATTRIBUTE_SUCCESS",
    ADD_ATTRIBUTE_FAILURE: "ADD_ATTRIBUTE_FAILURE",

    START_UPDATE_ATTRIBUTE: "START_UPDATE_ATTRIBUTE",
    UPDATE_ATTRIBUTE_SUCCESS: "UPDATE_ATTRIBUTE_SUCCESS",
    UPDATE_ATTRIBUTE_FAILURE: "UPDATE_ATTRIBUTE_FAILURE",

    START_DELETE_ATTRIBUTE: "START_DELETE_ATTRIBUTE",
    DELETE_ATTRIBUTE_SUCCESS: "DELETE_ATTRIBUTE_SUCCESS",
    DELETE_ATTRIBUTE_FAILURE: "DELETE_ATTRIBUTE_FAILURE",

    START_GET_ATTRIBUTE_BY_ID: "START_GET_ATTRIBUTE_BY_ID",
    GET_ATTRIBUTE_BY_ID_SUCCESS: "GET_ATTRIBUTE_BY_ID_SUCCESS",
    GET_ATTRIBUTE_BY_ID_FAILURE: "GET_ATTRIBUTE_BY_ID_FAILURE",

    START_GET_ATTRIBUTES: "START_GET_ATTRIBUTES",
    GET_ATTRIBUTES_SUCCESS: "GET_ATTRIBUTES_SUCCESS",
    GET_ATTRIBUTES_FAILURE: "GET_ATTRIBUTES_FAILURE",

    START_ADD_ATTRIBUTE_VALUE: "START_ADD_ATTRIBUTE_VALUE",
    ADD_ATTRIBUTE_VALUE_SUCCESS: "ADD_ATTRIBUTE_VALUE_SUCCESS",
    ADD_ATTRIBUTE_VALUE_FAILURE: "ADD_ATTRIBUTE_VALUE_FAILURE",

    START_UPDATE_ATTRIBUTE_VALUE: "START_UPDATE_ATTRIBUTE_VALUE",
    UPDATE_ATTRIBUTE_VALUE_SUCCESS: "UPDATE_ATTRIBUTE_VALUE_SUCCESS",
    UPDATE_ATTRIBUTE_VALUE_FAILURE: "UPDATE_ATTRIBUTE_VALUE_FAILURE",

    START_DELETE_ATTRIBUTE_VALUE: "START_DELETE_ATTRIBUTE_VALUE",
    DELETE_ATTRIBUTE_VALUE_SUCCESS: "DELETE_ATTRIBUTE_VALUE_SUCCESS",
    DELETE_ATTRIBUTE_VALUE_FAILURE: "DELETE_ATTRIBUTE_VALUE_FAILURE",

    START_GET_ATTRIBUTE_VALUES: "START_GET_ATTRIBUTE_VALUES",
    GET_ATTRIBUTE_VALUES_SUCCESS: "GET_ATTRIBUTE_VALUES_SUCCESS",
    GET_ATTRIBUTE_VALUES_FAILURE: "GET_ATTRIBUTE_VALUES_FAILURE",

    START_CONNECT_ATTRIBUTE_WITH_PRODUCT: "START_CONNECT_ATTRIBUTE_WITH_PRODUCT",
    CONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS: "CONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS",
    CONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE: "CONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE",

    START_DISCONNECT_ATTRIBUTE_WITH_PRODUCT: "START_DISCONNECT_ATTRIBUTE_WITH_PRODUCT",
    DISCONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS: "DISCONNECT_ATTRIBUTE_WITH_PRODUCT_SUCCESS",
    DISCONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE: "DISCONNECT_ATTRIBUTE_WITH_PRODUCT_FAILURE",
};
