export const accountActionTypes = {
    START_LOGIN: 'START_LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
  
    START_REGISTER: 'START_REGISTER',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
  
    START_RESET_USER_PASSWORD: 'START_RESET_USER_PASSWORD',
    RESET_USER_PASSWORD_SUCCESS: 'RESET_USER_PASSWORD_SUCCESS',
    RESET_USER_PASSWORD_FAILURE: 'RESET_USER_PASSWORD_FAILURE',

    START_CHANGE_USER_PASSWORD: 'START_CHANGE_USER_PASSWORD',
    CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
    CHANGE_USER_PASSWORD_FAILURE: 'CHANGE_USER_PASSWORD_FAILURE',

    START_GET_USER_INFO: 'START_GET_USER_INFO',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
  
    LOGOUT: 'LOGOUT',
  }
  