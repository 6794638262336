import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const AlertService = {
  success: (title, text) => {
    return MySwal.fire({
      icon: "success",
      title,
      text,
      showConfirmButton: true,
    });
  },
  error: (title, text) => {
    return MySwal.fire({
      icon: "error",
      title,
      text,
      showConfirmButton: true,
    });
  },
  warning: (title, text) => {
    return MySwal.fire({
      icon: "warning",
      title,
      text,
      showConfirmButton: true,
    });
  },
  info: (title, text) => {
    return MySwal.fire({
      icon: "info",
      title,
      text,
      showConfirmButton: true,
    });
  },
  confirm: async (title, text, confirmButtonText = "Yes", cancelButtonText = "No") => {
    const result = await MySwal.fire({
      title,
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    });

    return result.isConfirmed;
  },
  /**
   * Show alert with HTML content
   * @param {string} title - The title of the alert
   * @param {string} htmlContent - The HTML string to display
   * @param {string} icon - The icon type (success, error, warning, info)
   */
  htmlAlert: (title, htmlContent, icon = "info") => {
    return MySwal.fire({
      icon,
      title,
      html: htmlContent,
      showConfirmButton: true,
    });
  },
};

export default AlertService;
