// Product Action Types (actionTypes/products/product.actionTypes.js)
export const productActionTypes = {
    START_ADD_PRODUCT: "START_ADD_PRODUCT",
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
    ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",

    START_UPDATE_PRODUCT: "START_UPDATE_PRODUCT",
    UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
    UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",

    START_DELETE_PRODUCT: "START_DELETE_PRODUCT",
    DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
    DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",

    START_GET_PRODUCT: "START_GET_PRODUCT",
    GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
    GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",

    START_GET_PRODUCTS: "START_GET_PRODUCTS",
    GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
    GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

    START_GET_PRODUCT_IMAGES: "START_GET_PRODUCT_IMAGES",
    GET_PRODUCT_IMAGES_SUCCESS: "GET_PRODUCT_IMAGES_SUCCESS",
    GET_PRODUCT_IMAGES_FAILURE: "GET_PRODUCT_IMAGES_FAILURE",

    START_ADD_PRODUCT_IMAGE: "START_ADD_PRODUCT_IMAGE",
    ADD_PRODUCT_IMAGE_SUCCESS: "ADD_PRODUCT_IMAGE_SUCCESS",
    ADD_PRODUCT_IMAGE_FAILURE: "ADD_PRODUCT_IMAGE_FAILURE",

    START_DELETE_PRODUCT_IMAGE: "START_DELETE_PRODUCT_IMAGE",
    DELETE_PRODUCT_IMAGE_SUCCESS: "DELETE_PRODUCT_IMAGE_SUCCESS",
    DELETE_PRODUCT_IMAGE_FAILURE: "DELETE_PRODUCT_IMAGE_FAILURE",

    START_SET_IMAGE_AS_PRIMARY: "START_SET_IMAGE_AS_PRIMARY",
    SET_IMAGE_AS_PRIMARY_SUCCESS: "SET_IMAGE_AS_PRIMARY_SUCCESS",
    SET_IMAGE_AS_PRIMARY_FAILURE: "SET_IMAGE_AS_PRIMARY_FAILURE",
};
