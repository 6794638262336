import "../../../styles/components/elements/inputElements/switchElementComponent.scss";

export const SwitchElementComponent = ({ props }) => {
  const {
    name,
    label,
    checked,
    onChange,
    required,
    clearDesign,
    customDesignClass = "",
    style = {}, // Inline custom styles
    customSwitchClass = "", // Additional custom switch class
    disabled = false,
  } = props;

  return (
    <div
      className={`${clearDesign ? "" : "switchElementContainer"} ${customDesignClass} defaultSwitch`}
      style={style} // Apply custom styles to the container
      onClick={(e) => {
        if (!disabled) {
          e.stopPropagation();
          onChange({ target: { name, checked: !checked } }); // Manually trigger onChange
        }
      }}
    >
      {label && <label className="switchLabel">{label}</label>}
      <div className={`switchWrapper ${customSwitchClass}`}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={() => {}} // Prevent default event propagation
          required={required}
          disabled={disabled}
          className="defaultSwitchStyle"
        />
        <span className="slider"></span>
      </div>
    </div>
  );
};

export default SwitchElementComponent;
