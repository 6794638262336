import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>  {/* ✅ Ensures Redux is available */}
      <App />
    </Provider>
  </React.StrictMode>
);

// Measure performance (optional)
reportWebVitals();


// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./styles/index.scss";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import '@fortawesome/fontawesome-free/css/all.min.css';


// // Import the Redux store and Provider
// import { Provider } from "react-redux";
// import store from "./store"; // Assuming your store is in the src/store/index.js file

// // Import BrowserRouter
// import { BrowserRouter } from "react-router-dom";
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     {/* Wrap your app with BrowserRouter for routing and Provider for Redux */}
//     <BrowserRouter>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </BrowserRouter>
//   </React.StrictMode>
// );

// // Measure performance (optional)
// reportWebVitals();
